import { createContext, useContext, useMemo } from "react";

export const StoreContext = createContext({})

export const useStore = () => {
	const { store, setItem } = useContext(StoreContext)
	const isDark = useMemo(() => {
		return store && store['theme-mode'] === 'dark'
	}, [store])
	return {
		store,
		setItem,
		isDark
	}
}
