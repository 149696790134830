import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import HeaderBar from '../components/HeaderBar';
import { Layout } from '@douyinfe/semi-ui';
import { ToastContainer } from 'react-toastify';
import { styled } from 'styled-components';
import { intl } from '../lang';
import { Link } from 'react-router-dom';

const { Content } = Layout;

const LayoutContainer = styled.div`
	.header-container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: 600px;
		background-image: url('./stats-background.png'), linear-gradient(0deg, rgba(33, 84, 210, 0.1), rgba(33, 84, 210, 0.1));
		background-size: cover;
		background-position: 80% 85%;
		border-radius: 0 0 72px 72px;
		color: white;
		--semi-color-text-2: white;
		overflow: hidden;
		.header-warpper {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: fit-content;
			max-width: 100%;
		}
		.semi-layout {
			flex: initial;
			width: 100%;
			height: auto;
			.semi-navigation-item {
				margin-bottom: 0;
			}
			.semi-navigation {
				width: 100%;
				max-width: 100%;
				.semi-navigation-list {
					gap: 8px;
				}
				.semi-navigation-inner {
					width: 100%;
					justify-content: space-between;
					.semi-navigation-item {
						position: relative;
						&::after {
							content: '';
							position: absolute;
							left: 0;
							height: 1px;
							width: 0;
							bottom: -2px;
							background-color: white;
							transition: all 0.5s;
						}
						&:hover {
							&::after {
								width: 100%;
							}
						}
					}
					.semi-navigation-item-text {
						font-size: 16px;
						font-weight: 500;
						color: white !important;
					}
					.semi-navigation-footer {
						display: flex;
						justify-content: flex-end;
						min-width: 160px;
						padding: 12px 8px;
					}
					.semi-navigation-header-list-outer {
						overflow: auto;
						&::-webkit-scrollbar {
							width: 8px;
							height: 8px;
							border-radius: 4px;
						}
					}
				}
			}
		}

		.dot {
			position: absolute;
			bottom: 48px;
			right: 0;
			width: 208px;
			height: 128px;
			background: conic-gradient(#518ab1, #3a308d);
			border-radius: 82px;
			transform: translateX(50%);
		}
		.semi-navigation-horizontal {
			background: transparent;
		}

		.header-context {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 32px;
			height: 540px;
			padding-top: 24px;
			padding-left: 42px;
			padding-bottom: 52px;

			.label {
				font-size: 42px;
				.header-label {
					width: 560px;
				}
				.label-footer {
					margin-top: 4px;
					display: flex;
					align-items: center;
					.span {
						display: flex;
						flex-direction: column;
						height: 72px;
						line-height: 62.4px;
						padding: 4px 12px;
						margin: 12px 6px;
						background: #3a308d;
						overflow: hidden;
						border-radius: 24px;
						.semi-carousel-content {
							transform: rotate(90deg);
						}
						.semi-carousel-indicator {
							display: none;
						}
						.semi-carousel-content-item {
							text-align: center;
						}
						.api-container {
							line-height: 62.4px;
							width: 260px;
							border-radius: 8px;
							position: relative;
							transition: all 1s;
							.item {
								position: absolute;
								display: flex;
								justify-content: center;
								align-items: center;
								height: 62px;
								width: 100%;
								visibility: hidden;
								&:first-child {
									visibility: visible;
								}
							}
						}
					}
				}
			}

			.started-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 267px;
				height: 61px;
				background: rgb(201, 242, 239);
				color: rgb(0, 85, 218);
				font-family: 'Public Sans';
				line-height: 150%;
				font-size: 22px;
				font-weight: 400;
				border-radius: 60px;
				cursor: pointer;
			}
		}
	}

	.footer-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 442px;
		background-color: #0055da;
		border-radius: 4px 4px 0px 0px;

		.footer-warpper {
			display: flex;
			flex-direction: column;
			gap: 110px;
			width: 1260px;
			height: 100%;
			padding: 60px 0px 20px 0px;
			color: white;

			.footer-message {
				display: flex;
				gap: 224px;
				flex: 1;
				height: 0;
				.footer-menu-container {
					display: grid;
					grid-template-columns: repeat(4, minmax(0, 1fr));
					flex: 1;
					width: 0;
					font-size: 16px;
					.footer-menu-item {
						display: flex;
						flex-direction: column;
						gap: 16px;
						.menu {
							position: relative;
							width: fit-content;
							cursor: pointer;
							&:first-child {
								color: rgb(201, 242, 239);
							}
							&::after {
								content: '';
								position: absolute;
								left: 0;
								bottom: -5px;
								width: 0;
								height: 1px;
								background: white;
								transition: all 0.5s;
								border-radius: 1px;
							}

							&:hover {
								&::after {
									width: 110%;
								}
							}
						}
					}
				}
			}
			.footer-footer {
				display: flex;
				gap: 24px;
			}
		}
	}

	.intoCarousel {
		animation: intoCarousel 0.5s 1 linear forwards;
	}
	.outCarousel {
		animation: outCarousel 0.5s 1 linear forwards;
	}
	@keyframes intoCarousel {
		from {
			transform: translateY(100%);
		}
		100% {
			transform: translateY(0);
		}
	}
	@keyframes outCarousel {
		from {
			transform: translateY(0);
		}
		100% {
			transform: translateY(-100%);
		}
	}
`;

const lang = localStorage.getItem('lang') ?? 'zh';

const DefaultLayout = (props) => {
	const [apis] = useState([
		intl.get('GPT-4-series'),
		intl.get('DALL-E-series'),
		intl.get('TTS-series'),
		intl.get('Midjourney'),
		intl.get('Claude'),
		intl.get('Gemini'),
	]);
	const carousel = useRef();
	const timer = useRef(0);
	const onCarousel = useCallback(() => {
		if (!carousel.current) {
			return;
		}
		const childrenNode = carousel.current?.childNodes;
		let index = 0;
		const onNext = (index) => {
			let prev, next;
			if (index === 0) {
				prev = childrenNode[childrenNode.length - 1];
			} else {
				prev = childrenNode[index - 1];
			}
			prev.classList.remove('intoCarousel');
			prev.classList.add('outCarousel');

			next = childrenNode[index];
			next.style.visibility = 'visible';
			next.classList.remove('outCarousel');
			next.classList.add('intoCarousel');
			setTimeout(() => {
				prev.style.visibility = 'hidden';
			}, 500);
		};
		timer.current = setInterval(() => {
			if (index === 5) {
				onNext(0);
				index = 0;
			} else {
				onNext(++index);
			}
		}, 1000);
	}, [carousel]);
	useEffect(() => {
		onCarousel();
		return () => clearInterval(timer.current);
	}, [onCarousel]);
	const [innerWidth, setInnerWidth] = useState(window.innerWidth / 1480);
	useEffect(() => {
		window.addEventListener('resize', () => {
			setInnerWidth(window.innerWidth / 1480);
		});
		return () => window.removeEventListener('resize', () => void 0);
	});

	const location = useLocation();
	const isHome = useMemo(() => {
		const shows = ['/'];
		return shows.includes(window.location.pathname);
	}, [location]);
	const title = useMemo(() => {
		const shows = {
			'/': '',
			'/privacy': 'Privacy Policy',
			'/terms': 'Terms of Service',
		};
		return shows[window.location.pathname];
	}, []);

	return (
		<LayoutContainer>
			<Layout>
				<Layout>
					<div className='header-container'>
						<div className='header-warpper'>
							<HeaderBar />
							<div className='dot'></div>
							{
								isHome && (

									<div className='header-context'>
									<h1 className='label'>
										<div
											style={{
												width: ['zh', 'zh-tw'].includes(lang) ? 560 * innerWidth : 960 * innerWidth + 'px',
											}}
											className='header-label'
										>
											{intl.get('top-first-edition-content')}
										</div>
										<div className='label-footer'>
											{intl.get('top-first-edition-to')}
											<span className='span'>
												<span
													ref={carousel}
													style={{
														bottom: '0px',
													}}
													className='api-container'
												>
													{apis.map((api, index) => (
														<span key={index} className='item'>
															{api}
														</span>
													))}
												</span>
											</span>
											{intl.get('api')}
										</div>
									</h1>
									<Link to="/api-keys">
									<div className='started-btn'>{intl.get('get-started-for-free')}</div>
									</Link>
									
								</div>
								)
							}
							{
								!isHome && (
									<div className='header-context'>
										<h1 style={{
											fontSize: '64px'
										}}>{title}</h1>
									</div>
								)
							}
						</div>
					</div>
					<Content>{props.children}</Content>
					<Layout.Footer>
						<HomeFooter></HomeFooter>
					</Layout.Footer>
				</Layout>
				<ToastContainer />
			</Layout>
		</LayoutContainer>
	);
};

export default DefaultLayout;
const HomeFooter = () => {
	const redirect = useCallback((url = 'https://www.doublegpt.io') => {
		window.location.replace(url);
	}, []);
	return (
		<div className='footer-container'>
			<div className='footer-warpper'>
				<div className='footer-message'>
					<div className='follow-us'>
						<img src='./logo.svg' />
					</div>
					<div className='footer-menu-container'>
						<div className='footer-menu-item'>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-1-1')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-1-2')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-1-3')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-1-4')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-1-5')}
							</div>
						</div>
						<div className='footer-menu-item'>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-2-1')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-2-2')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-2-3')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-2-4')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-2-5')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-2-6')}
							</div>
						</div>
						<div className='footer-menu-item'>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-3-1')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-3-2')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-3-3')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-3-4')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-3-5')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-3-6')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-3-7')}
							</div>
						</div>
						<div className='footer-menu-item'>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-4-1')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-4-2')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-4-3')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-4-4')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-4-5')}
							</div>
							<div onClick={() => redirect()} className='menu'>
								{intl.get('footer-menu-item-4-6')}
							</div>
						</div>
					</div>
				</div>
				<div className='footer-footer'>
					<div>
						{lang === 'en' && (
							<>
								<p className='version'>© 2023 OPENAI365 LLC</p>
							</>
						)}
						{lang === 'fr' && (
							<>
								<p className='version'>© 2023 OPENAI365 LLC</p>
							</>
						)}
						{lang === 'de' && (
							<>
								<p className='version'>© 2023 OPENAI365 LLC</p>
							</>
						)}
						{lang === 'es' && (
							<>
								<p className='version'>© 2023 OPENAI365 LLC</p>
							</>
						)}
						{lang === 'ko' && (
							<>
								<p className='version'>© 2023 OPENAI365 LLC</p>
							</>
						)}
						{lang === 'ja' && (
							<>
								<p className='version'>© 2023年株式会社OPENAI365</p>
							</>
						)}
						{lang === 'zh-tw' && (
							<>
								<p className='version'>© 2023 OPENAI365有限公司</p>
							</>
						)}
						{lang === 'zh' && (
							<>
								<p className='version'>© 2023 OPENAI365有限公司</p>
							</>
						)}
					</div>
					<div
						style={{
							cursor: 'pointer',
						}}
						onClick={() => redirect('/terms')}
					>
						TERMS OF SERVICE
					</div>
					<div
						style={{
							cursor: 'pointer',
						}}
						onClick={() => redirect('/privacy')}
					>
						PRIVACY
					</div>
				</div>
			</div>
		</div>
	);
};
