import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Grid, Header, Modal, Message } from 'semantic-ui-react';
import { API, removeTrailingSlash, showError, showSuccess, verifyJSON } from '../helpers';
import { Toast, Select, Skeleton } from '@douyinfe/semi-ui';
import { getPaySelect, getPaySettingConfig } from '../helpers/pay-setting';
import styled from 'styled-components';

import { intl } from '../lang';

const PayForm = styled.div`
	.form-context {
		display: grid;
		gap: 8px;
		min-height: 76px;
		grid-template-columns: repeat(5, minmax(0, 1fr));

		.select-context {
			display: flex;
			flex-direction: column;
			gap: 4px;
			.label {
				display: block;
				color: rgba(0, 0, 0, 0.87);
				font-size: 0.92857143em;
				font-weight: 700;
				text-transform: none;
			}
			.semi-select {
				height: 38px;
			}
		}
		.semi-skeleton-button {
			width: 100%;
			height: 100%;
		}
	}
	@media screen and (max-width: 1480px) {
		.form-context {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}
`;

const formGroup = [
	'Notice',
	'ServerAddress',
	'EpayId',
	'EpayKey',
	'Price',
	'PayAddress',
	'GitHubClientId',
	'GitHubClientSecret',
	'WeChatServerAddress',
	'WeChatServerToken',
	'WeChatAccountQRCodeImageURL',
	'TurnstileSiteKey',
	'TurnstileSecretKey',
	'EmailDomainWhitelist',
	'TopupGroupRatio',
	'payID',
	'api_location',
	'merchant_id',
	'merchant_key',
	'TopupRatio',
];
const SystemSetting = () => {
	let [inputs, setInputs] = useState({
		PasswordLoginEnabled: '',
		PasswordRegisterEnabled: '',
		EmailVerificationEnabled: '',
		GitHubOAuthEnabled: '',
		GitHubClientId: '',
		GitHubClientSecret: '',
		Notice: '',
		SMTPServer: '',
		SMTPPort: '',
		SMTPAccount: '',
		SMTPFrom: '',
		SMTPToken: '',
		ServerAddress: '',
		EpayId: '',
		EpayKey: '',
		Price: 7.3,
		TopupGroupRatio: '',
		PayAddress: '',
		Footer: '',
		WeChatAuthEnabled: '',
		WeChatServerAddress: '',
		WeChatServerToken: '',
		WeChatAccountQRCodeImageURL: '',
		TurnstileCheckEnabled: '',
		TurnstileSiteKey: '',
		TurnstileSecretKey: '',
		RegisterEnabled: '',
		EmailDomainRestrictionEnabled: '',
		EmailDomainWhitelist: '',
		// 支付渠道ID
		payID: '',
		// 支付域名
		api_location: '',
		// 商户ID
		merchant_id: '',
		// 商户密钥
		merchant_key: '',
		// 充值价格
		price: '7.3',
		// 充值倍率
		TopupRatio: '',
	});
	const [originInputs, setOriginInputs] = useState({});
	let [loading, setLoading] = useState(false);
	const [EmailDomainWhitelist, setEmailDomainWhitelist] = useState([]);
	const [restrictedDomainInput, setRestrictedDomainInput] = useState('');
	const [showPasswordWarningModal, setShowPasswordWarningModal] = useState(false);

	const getOptions = async () => {
		const res = await API.get('/api/option/');
		const { success, message, data } = res.data;
		if (success) {
			let newInputs = {};
			data.forEach((item) => {
				if (['TopupGroupRatio', 'TopupRatio'].includes(item.key)) {
					try {
						item.value = JSON.stringify(JSON.parse(item.value ?? {}), null, 2);
					}catch {}
				}
				newInputs[item.key] = item.value;
			});
			// 处理支付
			setPayLoading(true);
			const { success, data: selectData, message } = await getPaySelect();
			if (success) {
				setPaySelect(selectData);
			} else {
				Toast.error(message);
			}
			const payConfig = await getPaySettingConfig(selectData[0].ID || 'EPAY');
			setPayLoading(false);
			if (payConfig.success) {
				setPayConfig(payConfig.data.formConfig);
			} else {
				Toast.error(message);
			}
			// 获取当前激活的支付渠道
			const { data: payActive } = await API.get('/api/user/get_active_pay_channel');
			let channel = -1;
			let payID = selectData[0].ID;
			if (payActive.data) {
				channel = payActive.data.pay_channel;
				payID = payActive.data.pay_channel === 0 ? 'EPAY' : 'STRIPE';
			} else {
				channel = (payID || 'EPAY') === 'EPAY' ? 0 : 2;
				await API.get('api/user/pay_manage/active_config?pay_channel=' + channel);
			}
			const result = await API.get('/api/user/pay_manage/get_config?pay_channel=' + channel);
			const { data: resultData } = result.data;
			console.log(result.data.success, 'result.success result.success');
			if (result.data.success) {
				setInputs({
					...newInputs,
					EmailDomainWhitelist: newInputs.EmailDomainWhitelist.split(','),
					payID: payID,
					api_location: resultData.api_location,
					merchant_id: resultData.merchant_id,
					merchant_key: resultData.merchant_key,
					price: resultData.price,
				});
			} else {
				setInputs({
					...newInputs,
					payID: payID,
					EmailDomainWhitelist: newInputs.EmailDomainWhitelist.split(','),
				});
			}

			setOriginInputs(newInputs);
			setEmailDomainWhitelist(
				newInputs.EmailDomainWhitelist.split(',').map((item) => {
					return { key: item, text: item, value: item };
				})
			);
		} else {
			showError(message);
		}
	};

	useEffect(() => {
		getOptions().then();
	}, []);

	const updateOption = async (key, value) => {
		setLoading(true);
		switch (key) {
			case 'PasswordLoginEnabled':
			case 'PasswordRegisterEnabled':
			case 'EmailVerificationEnabled':
			case 'GitHubOAuthEnabled':
			case 'WeChatAuthEnabled':
			case 'TurnstileCheckEnabled':
			case 'EmailDomainRestrictionEnabled':
			case 'RegisterEnabled':
				value = inputs[key] === 'true' ? 'false' : 'true';
				break;
			default:
				break;
		}
		const res = await API.put('/api/option/', {
			key,
			value,
		});
		const { success, message } = res.data;
		if (success) {
			if (key === 'EmailDomainWhitelist') {
				value = value.split(',');
			}
			if (key === 'Price') {
				value = parseFloat(value);
			}
			setInputs((inputs) => ({
				...inputs,
				[key]: value,
			}));
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const handleInputChange = async (e, { name, value }) => {
		// console.log(name, value, 'handleInputChange');
		if (name === 'PasswordLoginEnabled' && inputs[name] === 'true') {
			// block disabling password login
			setShowPasswordWarningModal(true);
			return;
		}
		if (formGroup.includes(name) || name.startsWith('SMTP')) {
			setInputs((inputs) => ({ ...inputs, [name]: value }));
		} else {
			await updateOption(name, value);
		}
	};

	const submitServerAddress = async () => {
		let ServerAddress = removeTrailingSlash(inputs.ServerAddress);
		await updateOption('ServerAddress', ServerAddress);
	};

	/**
	 * 支付修改
	 * @returns
	 */
	const submitPayAddress = async () => {
		if (inputs.ServerAddress === '') {
			showError(intl.get('components.SystemSetting.please-enter-the-server-address-first'));
			return;
		}
		if (inputs.TopupGroupRatio && originInputs['TopupGroupRatio'] !== inputs.TopupGroupRatio) {
			if (!verifyJSON(inputs.TopupGroupRatio)) {
				showError(intl.get('components.SystemSetting.recharge-group-multiplier-not-a-valid-JSON'));
				return;
			}
			await updateOption('TopupGroupRatio', inputs.TopupGroupRatio);
		}
		setLoading(true);
		if (!inputs.api_location || !inputs.merchant_key) {
			setLoading(false);
			showError(intl.get('components.SystemSetting.the-payment-domain-name-and-merchant-key-cannot-be-empty'));
			return;
		}
		const res = await API.post('/api/user/pay_manage/set_config', {
			api_location: inputs.api_location,
			merchant_id: inputs.merchant_id,
			merchant_key: inputs.merchant_key,
			price: inputs.price,
			pay_channel: inputs.payID === 'EPAY' ? 0 : 2,
		});
		const { success, message } = res.data;
		if (!success) {
			showError(message);
		}
		setLoading(false);
	};

	// 充值倍率
	const submitPayTopupRatio = async () => {
		await updateOption('TopupRatio', inputs.TopupRatio);
		showSuccess(intl.get('operation-completed-successfully'))
	}

	const submitSMTP = async () => {
		if (originInputs['SMTPServer'] !== inputs.SMTPServer) {
			await updateOption('SMTPServer', inputs.SMTPServer);
		}
		if (originInputs['SMTPAccount'] !== inputs.SMTPAccount) {
			await updateOption('SMTPAccount', inputs.SMTPAccount);
		}
		if (originInputs['SMTPFrom'] !== inputs.SMTPFrom) {
			await updateOption('SMTPFrom', inputs.SMTPFrom);
		}
		if (originInputs['SMTPPort'] !== inputs.SMTPPort && inputs.SMTPPort !== '') {
			await updateOption('SMTPPort', inputs.SMTPPort);
		}
		if (originInputs['SMTPToken'] !== inputs.SMTPToken && inputs.SMTPToken !== '') {
			await updateOption('SMTPToken', inputs.SMTPToken);
		}
	};

	const submitEmailDomainWhitelist = async () => {
		if (originInputs['EmailDomainWhitelist'] !== inputs.EmailDomainWhitelist.join(',') && inputs.SMTPToken !== '') {
			await updateOption('EmailDomainWhitelist', inputs.EmailDomainWhitelist.join(','));
		}
	};

	const submitWeChat = async () => {
		if (originInputs['WeChatServerAddress'] !== inputs.WeChatServerAddress) {
			await updateOption('WeChatServerAddress', removeTrailingSlash(inputs.WeChatServerAddress));
		}
		if (originInputs['WeChatAccountQRCodeImageURL'] !== inputs.WeChatAccountQRCodeImageURL) {
			await updateOption('WeChatAccountQRCodeImageURL', inputs.WeChatAccountQRCodeImageURL);
		}
		if (originInputs['WeChatServerToken'] !== inputs.WeChatServerToken && inputs.WeChatServerToken !== '') {
			await updateOption('WeChatServerToken', inputs.WeChatServerToken);
		}
	};

	const submitGitHubOAuth = async () => {
		if (originInputs['GitHubClientId'] !== inputs.GitHubClientId) {
			await updateOption('GitHubClientId', inputs.GitHubClientId);
		}
		if (originInputs['GitHubClientSecret'] !== inputs.GitHubClientSecret && inputs.GitHubClientSecret !== '') {
			await updateOption('GitHubClientSecret', inputs.GitHubClientSecret);
		}
	};

	const submitTurnstile = async () => {
		if (originInputs['TurnstileSiteKey'] !== inputs.TurnstileSiteKey) {
			await updateOption('TurnstileSiteKey', inputs.TurnstileSiteKey);
		}
		if (originInputs['TurnstileSecretKey'] !== inputs.TurnstileSecretKey && inputs.TurnstileSecretKey !== '') {
			await updateOption('TurnstileSecretKey', inputs.TurnstileSecretKey);
		}
	};

	const submitNewRestrictedDomain = () => {
		const localDomainList = inputs.EmailDomainWhitelist;
		if (restrictedDomainInput !== '' && !localDomainList.includes(restrictedDomainInput)) {
			setRestrictedDomainInput('');
			setInputs({
				...inputs,
				EmailDomainWhitelist: [...localDomainList, restrictedDomainInput],
			});
			setEmailDomainWhitelist([
				...EmailDomainWhitelist,
				{
					key: restrictedDomainInput,
					text: restrictedDomainInput,
					value: restrictedDomainInput,
				},
			]);
		}
	};

	const onPaySelect = async (value) => {
		let channel = (value || 'EPAY') === 'EPAY' ? 0 : 2;
		await API.get('api/user/pay_manage/active_config?pay_channel=' + channel);
		loadPayConifg(value);
	};

	// loading
	const [payLoading, setPayLoading] = useState(false);
	/**
	 * 支付渠道选择
	 */
	const [paySelect, setPaySelect] = useState([]);

	// 支付配置
	const [payConifg, setPayConfig] = useState([]);
	const loadPayConifg = async (payID) => {
		setPayLoading(true);
		const { success, data, message } = await getPaySettingConfig(payID || 'EPAY');
		setPayLoading(false);
		if (success) {
			setPayConfig(data.formConfig);
		} else {
			Toast.error(message);
		}
		const channel = (payID || 'EPAY') === 'EPAY' ? 0 : 2;
		const result = await API.get('/api/user/pay_manage/get_config?pay_channel=' + channel);
		if (result.status === 200) {
			const { success, data: resultData } = result.data;

			if (success) {
				setInputs({
					...inputs,
					payID: payID,
					api_location: resultData.api_location,
					merchant_id: resultData.merchant_id,
					merchant_key: resultData.merchant_key,
					price: resultData.price,
				});
			} else {
				setInputs({
					...inputs,
					payID: payID,
					api_location: '',
					merchant_id: '',
					merchant_key: '',
					price: 7.3,
				});
			}
		}
	};
	return (
		<Grid columns={1}>
			<Grid.Column>
				<Form loading={loading}>
					<Header as='h3'>{intl.get('components.SystemSetting.common-setting')}</Header>
					<Form.Group widths='equal'>
						<Form.Input
							label={intl.get('components.SystemSetting.server-address')}
							placeholder={intl.get('components.SystemSetting.for-example') + 'https://yourdomain.com'}
							value={inputs.ServerAddress}
							name='ServerAddress'
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Button onClick={submitServerAddress}>{intl.get('components.SystemSetting.update-server-address')}</Form.Button>
					<Divider />
					{/* <PaySetting inputs={inputs} events={{ handleInputChange }} /> */}

					<Header as='h3'>{intl.get('components.SystemSetting.pay-setting')}</Header>

					<PayForm>
						<div className='form-context'>
							<div className='select-context'>
								<span className='label'>{intl.get('components.SystemSetting.channel-of-disbursement')}</span>
								<Select
									optionList={paySelect.map((pay) => ({
										key: pay.ID,
										value: pay.ID,
										label: pay.Label,
									}))}
									onChange={onPaySelect}
									label={intl.get('components.SystemSetting.channel-of-disbursement')}
									value={inputs.payID}
									name='PaySelect'
								></Select>
							</div>
							{payLoading &&
								[0, 0, 0, 0].map(() => (
									<Skeleton
										loading={payLoading}
										style={{ width: '100%', height: '60px' }}
										placeholder={<Skeleton.Button rows={1} />}
									></Skeleton>
								))}
							{!payLoading &&
								payConifg.map((config, index) => {
									if (config.type === 'Input') {
										return (
											<Form.Input
												key={index}
												label={config.label}
												name={config.name}
												value={inputs[config.name]}
												onChange={handleInputChange}
											/>
										);
									}
									return (
										<Form.Input
											key={index}
											label={config.label}
											name={config.name}
											value={inputs[config.name]}
											onChange={handleInputChange}
										/>
									);
								})}
						</div>
					</PayForm>
					<Header as='h3'>{intl.get('components.SystemSetting.topup-group-ratio')}</Header>
					<Form.Group widths='equal'>
						<Form.TextArea
							name='TopupGroupRatio'
							onChange={handleInputChange}
							style={{ minHeight: 250, fontFamily: 'JetBrains Mono, Consolas' }}
							autoComplete='new-password'
							value={inputs.TopupGroupRatio}
							placeholder={intl.get('components.SystemSetting.is-JSON-key-is-group-name-value-is-magnification')}
						/>
					</Form.Group>
					<Form.Button onClick={submitPayAddress}>{intl.get('components.SystemSetting.update-payment-settings')}</Form.Button>
					<Divider />
					<Header as='h3'>{intl.get('components.SystemSetting.configure-login-registration')}</Header>
					<Form.Group inline>
						<Form.Checkbox
							checked={inputs.PasswordLoginEnabled === 'true'}
							label={intl.get('components.SystemSetting.password-login-enabled')}
							name='PasswordLoginEnabled'
							onChange={handleInputChange}
						/>
						{showPasswordWarningModal && (
							<Modal
								open={showPasswordWarningModal}
								onClose={() => setShowPasswordWarningModal(false)}
								size={'tiny'}
								style={{ maxWidth: '450px' }}
							>
								<Modal.Header>{intl.get('components.SystemSetting.warning')}</Modal.Header>
								<Modal.Content>
									<p>{intl.get('components.SystemSetting.cancel-password-login-warning')}</p>
								</Modal.Content>
								<Modal.Actions>
									<Button onClick={() => setShowPasswordWarningModal(false)}>
										{intl.get('components.SystemSetting.cancel')}
									</Button>
									<Button
										color='yellow'
										onClick={async () => {
											setShowPasswordWarningModal(false);
											await updateOption('PasswordLoginEnabled', 'false');
										}}
									>
										{intl.get('components.SystemSetting.yes')}
									</Button>
								</Modal.Actions>
							</Modal>
						)}
						<Form.Checkbox
							checked={inputs.PasswordRegisterEnabled === 'true'}
							label={intl.get('components.SystemSetting.password-register-enabled')}
							name='PasswordRegisterEnabled'
							onChange={handleInputChange}
						/>
						<Form.Checkbox
							checked={inputs.EmailVerificationEnabled === 'true'}
							label={intl.get('components.SystemSetting.email-verification-enabled')}
							name='EmailVerificationEnabled'
							onChange={handleInputChange}
						/>
						<Form.Checkbox
							checked={inputs.GitHubOAuthEnabled === 'true'}
							label={intl.get('components.SystemSetting.GitHub-oauth-enabled')}
							name='GitHubOAuthEnabled'
							onChange={handleInputChange}
						/>
						<Form.Checkbox
							checked={inputs.WeChatAuthEnabled === 'true'}
							label={intl.get('components.SystemSetting.WeChat-auth-enabled')}
							name='WeChatAuthEnabled'
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Group inline>
						<Form.Checkbox
							checked={inputs.RegisterEnabled === 'true'}
							label={intl.get('components.SystemSetting.register-enabled')}
							name='RegisterEnabled'
							onChange={handleInputChange}
						/>
						<Form.Checkbox
							checked={inputs.TurnstileCheckEnabled === 'true'}
							label={intl.get('components.SystemSetting.turnstile-check-enabled')}
							name='TurnstileCheckEnabled'
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Divider />
					<Header as='h3'>
						{intl.get('components.SystemSetting.configure-the-email-domain-name-whitelist')}
						<Header.Subheader>{intl.get('components.SystemSetting.prevent-deliberate-deception-bulk-register')}</Header.Subheader>
					</Header>
					<Form.Group widths={3}>
						<Form.Checkbox
							label={intl.get('components.SystemSetting.email-domain-restriction-enabled')}
							name='EmailDomainRestrictionEnabled'
							onChange={handleInputChange}
							checked={inputs.EmailDomainRestrictionEnabled === 'true'}
						/>
					</Form.Group>
					<Form.Group widths={2}>
						<Form.Dropdown
							label={intl.get('components.SystemSetting.email-domain-whitelist-enabled')}
							placeholder={intl.get('components.SystemSetting.email-domain-whitelist-enabled')}
							name='EmailDomainWhitelist'
							required
							fluid
							multiple
							selection
							onChange={handleInputChange}
							value={inputs.EmailDomainWhitelist}
							autoComplete='new-password'
							options={EmailDomainWhitelist}
						/>
						<Form.Input
							label={intl.get('components.SystemSetting.add-a-new-allowed-email-domain-name')}
							action={
								<Button
									type='button'
									onClick={() => {
										submitNewRestrictedDomain();
									}}
								>
									{intl.get('components.SystemSetting.fill-in')}
								</Button>
							}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									submitNewRestrictedDomain();
								}
							}}
							autoComplete='new-password'
							placeholder={intl.get('components.SystemSetting.restricted-domain-input')}
							value={restrictedDomainInput}
							onChange={(e, { value }) => {
								setRestrictedDomainInput(value);
							}}
						/>
					</Form.Group>
					<Form.Button onClick={submitEmailDomainWhitelist}>
						{intl.get('components.SystemSetting.save-email-domain-whitelist')}
					</Form.Button>
					<Divider />
					<Header as='h3'>
						{intl.get('components.SystemSetting.configuration-SMTP')}
						<Header.Subheader>{intl.get('components.SystemSetting.used-to-support-system-email-delivery')}</Header.Subheader>
					</Header>
					<Form.Group widths={3}>
						<Form.Input
							label={intl.get('components.SystemSetting.SMTP-server-address')}
							name='SMTPServer'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.SMTPServer}
							placeholder={intl.get('components.SystemSetting.for-example') + 'smtp.qq.com'}
						/>
						<Form.Input
							label={intl.get('components.SystemSetting.SMTP-port')}
							name='SMTPPort'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.SMTPPort}
							placeholder={intl.get('components.SystemSetting.default-text') + intl.get('components.SystemSetting.default-port')}
						/>
						<Form.Input
							label={intl.get('components.SystemSetting.SMTP-account')}
							name='SMTPAccount'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.SMTPAccount}
							placeholder={intl.get('components.SystemSetting.usually-an-email-address')}
						/>
					</Form.Group>
					<Form.Group widths={3}>
						<Form.Input
							label={intl.get('components.SystemSetting.SMTP-from')}
							name='SMTPFrom'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.SMTPFrom}
							placeholder={intl.get('components.SystemSetting.usually-the-same-as-the-email-address')}
						/>
						<Form.Input
							label={intl.get('components.SystemSetting.SMTP-token')}
							name='SMTPToken'
							onChange={handleInputChange}
							type='password'
							autoComplete='new-password'
							checked={inputs.RegisterEnabled === 'true'}
							placeholder={intl.get('components.SystemSetting.sensitive-information-is-not-sent-to-the-front-end-display')}
						/>
					</Form.Group>
					<Form.Button onClick={submitSMTP}>{intl.get('components.SystemSetting.save-SMTP-configuration')}</Form.Button>
					<Divider />
					<Header as='h3'>
						{intl.get('components.SystemSetting.Configure-GitHub-OAuth-App')}
						<Header.Subheader>
							{intl.get('components.SystemSetting.used-to-support-logins-and-registrations-via-GitHub')}
							<a href='https://github.com/settings/developers' target='_blank'>
								{intl.get('components.SystemSetting.hit-here')}
							</a>
							{intl.get('components.SystemSetting.manage-your-GitHub-OAuth-App')}
						</Header.Subheader>
					</Header>
					<Message>
						{intl.get('components.SystemSetting.homepage-url')} <code>{inputs.ServerAddress}</code>
						{intl.get('components.SystemSetting.authorization-callback-url')}{' '}
						<code>{`${inputs.ServerAddress}/oauth/github`}</code>
					</Message>
					<Form.Group widths={3}>
						<Form.Input
							label='GitHub Client ID'
							name='GitHubClientId'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.GitHubClientId}
							placeholder={intl.get('components.SystemSetting.enter-the-ID-of-the-GitHub-OAuth-APP-you-registered-with')}
						/>
						<Form.Input
							label='GitHub Client Secret'
							name='GitHubClientSecret'
							onChange={handleInputChange}
							type='password'
							autoComplete='new-password'
							value={inputs.GitHubClientSecret}
							placeholder={intl.get('components.SystemSetting.sensitive-information-is-not-sent-to-the-front-end-display')}
						/>
					</Form.Group>
					<Form.Button onClick={submitGitHubOAuth}>
						{intl.get('components.SystemSetting.save-the-github-oauth-settings')}
					</Form.Button>
					<Divider />
					<Header as='h3'>
						{intl.get('components.SystemSetting.configuring-wechat-server')}
						<Header.Subheader>
							{intl.get('components.SystemSetting.to-support-login-and-registration-through-wechat')}
							<a href='https://github.com/songquanpeng/wechat-server' target='_blank'>
								{intl.get('components.SystemSetting.hit-here')}
							</a>
							{intl.get('components.SystemSetting.about-WeChat-server')}
						</Header.Subheader>
					</Header>
					<Form.Group widths={3}>
						<Form.Input
							label={intl.get('components.SystemSetting.wechat-server-address')}
							name='WeChatServerAddress'
							placeholder={intl.get('components.SystemSetting.for-example') + 'https://yourdomain.com'}
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.WeChatServerAddress}
						/>
						<Form.Input
							label={intl.get('components.SystemSetting.wechat-server-token')}
							name='WeChatServerToken'
							type='password'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.WeChatServerToken}
							placeholder={intl.get('components.SystemSetting.sensitive-information-is-not-sent-to-the-front-end-display')}
						/>
						<Form.Input
							label={intl.get('components.SystemSetting.wechat-ems-cnpl-qr-code-image-link')}
							name='WeChatAccountQRCodeImageURL'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.WeChatAccountQRCodeImageURL}
							placeholder={intl.get('components.SystemSetting.enter-a-link-to-the-image')}
						/>
					</Form.Group>
					<Form.Button onClick={submitWeChat}>
						{intl.get('components.SystemSetting.save-wechat-server-configuration')}
					</Form.Button>
					<Divider />
					<Header as='h3'>
						{intl.get('components.SystemSetting.configure-turnstile')}
						<Header.Subheader>
							{intl.get('components.SystemSetting.to-support-user-verification')}
							<a href='https://dash.cloudflare.com/' target='_blank'>
								{intl.get('components.SystemSetting.hit-here')}
							</a>
							{intl.get('components.SystemSetting.manage-your-turnstile-sites')}
						</Header.Subheader>
					</Header>
					<Form.Group widths={3}>
						<Form.Input
							label='Turnstile Site Key'
							name='TurnstileSiteKey'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.TurnstileSiteKey}
							placeholder={intl.get('components.SystemSetting.enter-your-registered-turnstile-site-key')}
						/>
						<Form.Input
							label='Turnstile Secret Key'
							name='TurnstileSecretKey'
							onChange={handleInputChange}
							type='password'
							autoComplete='new-password'
							value={inputs.TurnstileSecretKey}
							placeholder={intl.get('components.SystemSetting.sensitive-information-is-not-sent-to-the-front-end-display')}
						/>
					</Form.Group>
					<Form.Button onClick={submitTurnstile}>{intl.get('components.SystemSetting.save-turnstile-settings')}</Form.Button>
				</Form>
			</Grid.Column>
		</Grid>
	);
};
export default SystemSetting;
