import React from 'react';
import { Message } from 'semantic-ui-react';
import { intl } from '../../lang';
const NotFound = () => (
	<>
		<Message negative>
			<Message.Header>{intl.get('pages.NotFound.index.page-not-found')}</Message.Header>
			<p>{intl.get('pages.NotFound.index.please-check-that-your-browser-address-is-correct')}</p>
		</Message>
	</>
);

export default NotFound;
