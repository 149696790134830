import intl from 'react-intl-universal';
const locales = {
	en: require('./modules/en.json'),
	zh: require('./modules/zh.json'),
	'zh-tw': require('./modules/zh-tw.json'),
	fr: require('./modules/fr.json'),
	de: require('./modules/de.json'),
	ja: require('./modules/ja.json'),
	ko: require('./modules/ko.json'),
	es: require('./modules/es.json'),
};
const defalutLang = localStorage.getItem('lang') || 'en';
console.log(defalutLang, 'defalutLang defalutLang');
intl.init({
	currentLocale: defalutLang,
	locales: locales,
});
const loadLocales = (lang) => {
	localStorage.setItem('lang', lang);
	window.location.reload();
};
export { intl, loadLocales };
