import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API, isMobile, showError, showInfo, showSuccess, verifyJSON } from '../../helpers';
import { CHANNEL_OPTIONS } from '../../constants';
import Title from '@douyinfe/semi-ui/lib/es/typography/title';
import { SideSheet, Space, Spin, Button, Input, Typography, Select, TextArea, Checkbox, Banner } from '@douyinfe/semi-ui';
import { intl } from '../../lang';

const MODEL_MAPPING_EXAMPLE = {
	'gpt-3.5-turbo-0301': 'gpt-3.5-turbo',
	'gpt-4-0314': 'gpt-4',
	'gpt-4-32k-0314': 'gpt-4-32k',
};

function type2secretPrompt(type) {
	// inputs.type === 15 ? '按照如下格式输入：APIKey|SecretKey' : (inputs.type === 18 ? '按照如下格式输入：APPID|APISecret|APIKey' : '请输入渠道对应的鉴权密钥')
	switch (type) {
		case 15:
			return `${intl.get('pages.Channel.EditChannel.enter-in-the-following-format')}APIKey|SecretKey`;
		case 18:
			return `${intl.get('pages.Channel.EditChannel.enter-in-the-following-format')}APPID|APISecret|APIKey`;
		case 22:
			return `${intl.get('pages.Channel.EditChannel.enter-in-the-following-format')}APIKey-AppId${intl.get(
				'pages.Channel.EditChannel.for-example'
			)}fastgpt-0sp2gtvfdgyi4k30jwlgwf1i-64f335d84283f05518e9e041`;
		case 23:
			return `${intl.get('pages.Channel.EditChannel.enter-in-the-following-format')}AppId|SecretId|SecretKey`;
		default:
			return `${intl.get('pages.Channel.EditChannel.enter-the-authentication-key-corresponding-to-the-channel')}`;
	}
}

const EditChannel = (props) => {
	const navigate = useNavigate();
	const channelId = props.editingChannel.id;
	const isEdit = channelId !== undefined;
	const [loading, setLoading] = useState(isEdit);
	const handleCancel = () => {
		props.handleClose();
	};
	const originInputs = {
		name: '',
		type: 1,
		key: '',
		openai_organization: '',
		base_url: '',
		other: '',
		model_mapping: '',
		models: [],
		auto_ban: 1,
		groups: ['default'],
	};
	const [batch, setBatch] = useState(false);
	const [autoBan, setAutoBan] = useState(true);
	// const [autoBan, setAutoBan] = useState(true);
	const [inputs, setInputs] = useState(originInputs);
	const [originModelOptions, setOriginModelOptions] = useState([]);
	const [modelOptions, setModelOptions] = useState([]);
	const [groupOptions, setGroupOptions] = useState([]);
	const [basicModels, setBasicModels] = useState([]);
	const [fullModels, setFullModels] = useState([]);
	const [customModel, setCustomModel] = useState('');
	const handleInputChange = (name, value) => {
		setInputs((inputs) => ({ ...inputs, [name]: value }));
		if (name === 'type' && inputs.models.length === 0) {
			let localModels = [];
			switch (value) {
				case 14:
					localModels = ['claude-instant-1', 'claude-2'];
					break;
				case 11:
					localModels = ['PaLM-2'];
					break;
				case 15:
					localModels = ['ERNIE-Bot', 'ERNIE-Bot-turbo', 'ERNIE-Bot-4', 'Embedding-V1'];
					break;
				case 17:
					localModels = ['qwen-turbo', 'qwen-plus', 'text-embedding-v1'];
					break;
				case 16:
					localModels = ['chatglm_pro', 'chatglm_std', 'chatglm_lite'];
					break;
				case 18:
					localModels = ['SparkDesk'];
					break;
				case 19:
					localModels = ['360GPT_S2_V9', 'embedding-bert-512-v1', 'embedding_s1_v1', 'semantic_similarity_s1_v1'];
					break;
				case 23:
					localModels = ['hunyuan'];
					break;
				case 24:
					localModels = ['gemini-pro'];
					break;
			}
			setInputs((inputs) => ({ ...inputs, models: localModels }));
		}
		//setAutoBan
	};

	const loadChannel = async () => {
		setLoading(true);
		let res = await API.get(`/api/channel/${channelId}`);
		const { success, message, data } = res.data;
		if (success) {
			if (data.models === '') {
				data.models = [];
			} else {
				data.models = data.models.split(',');
			}
			if (data.group === '') {
				data.groups = [];
			} else {
				data.groups = data.group.split(',');
			}
			if (data.model_mapping !== '') {
				data.model_mapping = JSON.stringify(JSON.parse(data.model_mapping), null, 2);
			}
			setInputs(data);
			if (data.auto_ban === 0) {
				setAutoBan(false);
			} else {
				setAutoBan(true);
			}
			// console.log(data);
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const fetchModels = async () => {
		try {
			let res = await API.get(`/api/channel/models`);
			let localModelOptions = res.data.data.map((model) => ({
				label: model.id,
				value: model.id,
			}));
			setOriginModelOptions(localModelOptions);
			setFullModels(res.data.data.map((model) => model.id));
			setBasicModels(
				res.data.data
					.filter((model) => {
						return model.id.startsWith('gpt-3') || model.id.startsWith('text-');
					})
					.map((model) => model.id)
			);
		} catch (error) {
			showError(error.message);
		}
	};

	const fetchGroups = async () => {
		try {
			let res = await API.get(`/api/group/`);
			setGroupOptions(
				res.data.data.map((group) => ({
					label: group,
					value: group,
				}))
			);
		} catch (error) {
			showError(error.message);
		}
	};

	useEffect(() => {
		let localModelOptions = [...originModelOptions];
		inputs.models.forEach((model) => {
			if (!localModelOptions.find((option) => option.key === model)) {
				localModelOptions.push({
					label: model,
					value: model,
				});
			}
		});
		setModelOptions(localModelOptions);
	}, [originModelOptions, inputs.models]);

	useEffect(() => {
		fetchModels().then();
		fetchGroups().then();
		if (isEdit) {
			loadChannel().then(() => {});
		} else {
			setInputs(originInputs);
		}
	}, [props.editingChannel.id]);

	const submit = async () => {
		if (!isEdit && (inputs.name === '' || inputs.key === '')) {
			showInfo(`${intl.get('pages.Channel.EditChannel.please-fill-in-the-channel-name-and-channel-key')}`);
			return;
		}
		if (inputs.models.length === 0) {
			showInfo(`${intl.get('pages.Channel.EditChannel.please-select-at-least-one-model')}`);
			return;
		}
		if (inputs.model_mapping !== '' && !verifyJSON(inputs.model_mapping)) {
			showInfo(`${intl.get('pages.Channel.EditChannel.the-model-map-must-be-in-a-valid-JSON-format')}`);
			return;
		}
		let localInputs = { ...inputs };
		if (localInputs.base_url && localInputs.base_url.endsWith('/')) {
			localInputs.base_url = localInputs.base_url.slice(0, localInputs.base_url.length - 1);
		}
		if (localInputs.type === 3 && localInputs.other === '') {
			localInputs.other = '2023-06-01-preview';
		}
		if (localInputs.type === 18 && localInputs.other === '') {
			localInputs.other = 'v2.1';
		}
		let res;
		if (!Array.isArray(localInputs.models)) {
			showError(`${intl.get('pages.Channel.EditChannel.please-do-not-repeat-submission')}`);
			handleCancel();
			return;
		}
		localInputs.models = localInputs.models.join(',');
		localInputs.group = localInputs.groups.join(',');
		if (isEdit) {
			res = await API.put(`/api/channel/`, { ...localInputs, id: parseInt(channelId) });
		} else {
			res = await API.post(`/api/channel/`, localInputs);
		}
		const { success, message } = res.data;
		if (success) {
			if (isEdit) {
				showSuccess(`${intl.get('pages.Channel.EditChannel.channel-renewal-success')}`);
			} else {
				showSuccess(`${intl.get('pages.Channel.EditChannel.channel-creation-success')}`);
				setInputs(originInputs);
			}
			props.refresh();
			props.handleClose();
		} else {
			showError(message);
		}
	};

	const addCustomModel = () => {
		if (customModel.trim() === '') return;
		if (inputs.models.includes(customModel)) return;
		let localModels = [...inputs.models];
		localModels.push(customModel);
		let localModelOptions = [];
		localModelOptions.push({
			key: customModel,
			text: customModel,
			value: customModel,
		});
		setModelOptions((modelOptions) => {
			return [...modelOptions, ...localModelOptions];
		});
		setCustomModel('');
		handleInputChange('models', localModels);
	};

	return (
		<>
			<SideSheet
				maskClosable={false}
				placement={isEdit ? 'right' : 'left'}
				title={
					<Title level={3}>
						{isEdit
							? `${intl.get('pages.Channel.EditChannel.update-channel-information')}`
							: `${intl.get('pages.Channel.EditChannel.create-new-channel')}`}
					</Title>
				}
				headerStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
				bodyStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
				visible={props.visible}
				footer={
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Space>
							<Button theme='solid' size={'large'} onClick={submit}>
								{intl.get('pages.Channel.EditChannel.submit')}
							</Button>
							<Button theme='solid' size={'large'} type={'tertiary'} onClick={handleCancel}>
								{intl.get('pages.Channel.EditChannel.cancel')}
							</Button>
						</Space>
					</div>
				}
				closeIcon={null}
				onCancel={() => handleCancel()}
				width={isMobile() ? '100%' : 600}
			>
				<Spin spinning={loading}>
					<div style={{ marginTop: 10 }}>
						<Typography.Text strong>{intl.get('pages.Channel.EditChannel.type')}</Typography.Text>
					</div>
					<Select
						name='type'
						required
						optionList={CHANNEL_OPTIONS}
						value={inputs.type}
						onChange={(value) => handleInputChange('type', value)}
						style={{ width: '50%' }}
					/>
					{inputs.type === 3 && (
						<>
							<div style={{ marginTop: 10 }}>
								<Banner
									type={'warning'}
									description={
										<>
											{intl.get('pages.Channel.EditChannel.attention')}
											<strong>
												{intl.get('pages.Channel.EditChannel.model-deployment-name-must-be-the-same-as-the-model-name')}
											</strong>
											{intl.get('pages.Channel.EditChannel.replace-warning')}
											<a
												target='_blank'
												href='https://github.com/songquanpeng/one-api/issues/133?notification_referrer_id=NT_kwDOAmJSYrM2NjIwMzI3NDgyOjM5OTk4MDUw#issuecomment-1571602271'
											>
												{intl.get('pages.Channel.EditChannel.image-view')}
											</a>
											{intl.get('pages.Channel.EditChannel.full-stop')}
										</>
									}
								></Banner>
							</div>
							<div style={{ marginTop: 10 }}>
								<Typography.Text strong>AZURE_OPENAI_ENDPOINT：</Typography.Text>
							</div>
							<Input
								label='AZURE_OPENAI_ENDPOINT'
								name='azure_base_url'
								placeholder={intl.get('pages.Channel.EditChannel.enter-AZURE_OPENAI_ENDPOINT')}
								onChange={(value) => {
									handleInputChange('base_url', value);
								}}
								value={inputs.base_url}
								autoComplete='new-password'
							/>
							<div style={{ marginTop: 10 }}>
								<Typography.Text strong>{intl.get('pages.Channel.EditChannel.API-version')}</Typography.Text>
							</div>
							<Input
								label={intl.get('pages.Channel.EditChannel.default-API-version')}
								name='azure_other'
								placeholder={intl.get('pages.Channel.EditChannel.please-enter-the-default-API-version')}
								onChange={(value) => {
									handleInputChange('other', value);
								}}
								value={inputs.other}
								autoComplete='new-password'
							/>
						</>
					)}
					{inputs.type === 8 && (
						<>
							<div style={{ marginTop: 10 }}>
								<Typography.Text strong>Base URL：</Typography.Text>
							</div>
							<Input
								name='base_url'
								placeholder={intl.get('pages.Channel.EditChannel.please-enter-a-custom-channel')}
								onChange={(value) => {
									handleInputChange('base_url', value);
								}}
								value={inputs.base_url}
								autoComplete='new-password'
							/>
						</>
					)}
					<div style={{ marginTop: 10 }}>
						<Typography.Text strong>{intl.get('pages.Channel.EditChannel.name')}</Typography.Text>
					</div>
					<Input
						required
						name='name'
						placeholder={intl.get('pages.Channel.EditChannel.please-name-the-channel')}
						onChange={(value) => {
							handleInputChange('name', value);
						}}
						value={inputs.name}
						autoComplete='new-password'
					/>
					<div style={{ marginTop: 10 }}>
						<Typography.Text strong>{intl.get('pages.Channel.EditChannel.group')}</Typography.Text>
					</div>
					<Select
						placeholder={intl.get('pages.Channel.EditChannel.please-select-a-group')}
						name='groups'
						required
						multiple
						selection
						allowAdditions
						additionLabel={intl.get('pages.Channel.EditChannel.edit-the-group')}
						onChange={(value) => {
							handleInputChange('groups', value);
						}}
						value={inputs.groups}
						autoComplete='new-password'
						optionList={groupOptions}
					/>
					{inputs.type === 18 && (
						<>
							<div style={{ marginTop: 10 }}>
								<Typography.Text strong>{intl.get('pages.Channel.EditChannel.model-version')}</Typography.Text>
							</div>
							<Input
								name='other'
								placeholder={intl.get('pages.Channel.EditChannel.please-enter-the-Spark-large-model-version')}
								onChange={(value) => {
									handleInputChange('other', value);
								}}
								value={inputs.other}
								autoComplete='new-password'
							/>
						</>
					)}
					{inputs.type === 21 && (
						<>
							<div style={{ marginTop: 10 }}>
								<Typography.Text strong>{intl.get('pages.Channel.EditChannel.knowledge-ID-desc')}</Typography.Text>
							</div>
							<Input
								label={intl.get('pages.Channel.EditChannel.knowledge-ID')}
								name='other'
								placeholder={intl.get('pages.Channel.EditChannel.please-enter-knowledge-ID')}
								onChange={(value) => {
									handleInputChange('other', value);
								}}
								value={inputs.other}
								autoComplete='new-password'
							/>
						</>
					)}
					<div style={{ marginTop: 10 }}>
						<Typography.Text strong>{intl.get('pages.Channel.EditChannel.model')}</Typography.Text>
					</div>
					<Select
						placeholder={intl.get('pages.Channel.EditChannel.please-select-the-model-supported-by-the-channel')}
						name='models'
						required
						multiple
						selection
						onChange={(value) => {
							handleInputChange('models', value);
						}}
						value={inputs.models}
						autoComplete='new-password'
						optionList={modelOptions}
					/>
					<div style={{ lineHeight: '40px', marginBottom: '12px' }}>
						<Space>
							<Button
								type='primary'
								onClick={() => {
									handleInputChange('models', basicModels);
								}}
							>
								{intl.get('pages.Channel.EditChannel.fill-in-the-base-model')}
							</Button>
							<Button
								type='secondary'
								onClick={() => {
									handleInputChange('models', fullModels);
								}}
							>
								{intl.get('pages.Channel.EditChannel.fill-all-models')}
							</Button>
							<Button
								type='warning'
								onClick={() => {
									handleInputChange('models', []);
								}}
							>
								{intl.get('pages.Channel.EditChannel.clear-all-models')}
							</Button>
						</Space>
						<Input
							addonAfter={
								<Button type='primary' onClick={addCustomModel}>
									{intl.get('pages.Channel.EditChannel.filling-in')}
								</Button>
							}
							placeholder={intl.get('pages.Channel.EditChannel.enter-a-custom-model-name')}
							value={customModel}
							onChange={(value) => {
								setCustomModel(value);
							}}
						/>
					</div>
					<div style={{ marginTop: 10 }}>
						<Typography.Text strong>{intl.get('pages.Channel.EditChannel.model-redirection')}</Typography.Text>
					</div>
					<TextArea
						placeholder={`${intl.get('pages.Channel.EditChannel.modify-the-model-name-in-the-request-body')}\n${JSON.stringify(
							MODEL_MAPPING_EXAMPLE,
							null,
							2
						)}`}
						name='model_mapping'
						onChange={(value) => {
							handleInputChange('model_mapping', value);
						}}
						autosize
						value={inputs.model_mapping}
						autoComplete='new-password'
					/>
					<Typography.Text
						style={{
							color: 'rgba(var(--semi-blue-5), 1)',
							userSelect: 'none',
							cursor: 'pointer',
						}}
						onClick={() => {
							handleInputChange('model_mapping', JSON.stringify(MODEL_MAPPING_EXAMPLE, null, 2));
						}}
					>
						{intl.get('pages.Channel.EditChannel.fill-form')}
					</Typography.Text>
					<div style={{ marginTop: 10 }}>
						<Typography.Text strong>{intl.get('pages.Channel.EditChannel.secret-key-desc')}</Typography.Text>
					</div>
					{batch ? (
						<TextArea
							label={intl.get('pages.Channel.EditChannel.secret-key')}
							name='key'
							required
							placeholder={intl.get('pages.Channel.EditChannel.please-enter-the-key-one-in-a-row')}
							onChange={(value) => {
								handleInputChange('key', value);
							}}
							value={inputs.key}
							style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
							autoComplete='new-password'
						/>
					) : (
						<Input
							label={intl.get('pages.Channel.EditChannel.secret-key')}
							name='key'
							required
							placeholder={type2secretPrompt(inputs.type)}
							onChange={(value) => {
								handleInputChange('key', value);
							}}
							value={inputs.key}
							autoComplete='new-password'
						/>
					)}
					<div style={{ marginTop: 10 }}>
						<Typography.Text strong>{intl.get('pages.Channel.EditChannel.organization')}</Typography.Text>
					</div>
					<Input
						label={intl.get('pages.Channel.EditChannel.select-organization')}
						name='openai_organization'
						placeholder={intl.get('pages.Channel.EditChannel.please-enter-organization')}
						onChange={(value) => {
							handleInputChange('openai_organization', value);
						}}
						value={inputs.openai_organization}
					/>
					<div style={{ marginTop: 10, display: 'flex' }}>
						<Space>
							<Checkbox
								name='auto_ban'
								checked={autoBan}
								onChange={() => {
									setAutoBan(!autoBan);
								}}
								// onChange={handleInputChange}
							/>
							<Typography.Text strong>{intl.get('pages.Channel.EditChannel.whether-to-disable-automatically')}</Typography.Text>
						</Space>
					</div>

					{!isEdit && (
						<div style={{ marginTop: 10, display: 'flex' }}>
							<Space>
								<Checkbox
									checked={batch}
									label={intl.get('pages.Channel.EditChannel.batch-create')}
									name='batch'
									onChange={() => setBatch(!batch)}
								/>
								<Typography.Text strong>{intl.get('pages.Channel.EditChannel.batch-create')}</Typography.Text>
							</Space>
						</div>
					)}
					{inputs.type !== 3 && inputs.type !== 8 && inputs.type !== 22 && (
						<>
							<div style={{ marginTop: 10 }}>
								<Typography.Text strong>{intl.get('pages.Channel.EditChannel.agency-desc')}</Typography.Text>
							</div>
							<Input
								label={intl.get('pages.Channel.EditChannel.agency')}
								name='base_url'
								placeholder={intl.get('pages.Channel.EditChannel.API-calls-are-made-through-proxy-stations')}
								onChange={(value) => {
									handleInputChange('base_url', value);
								}}
								value={inputs.base_url}
								autoComplete='new-password'
							/>
						</>
					)}
					{inputs.type === 22 && (
						<>
							<div style={{ marginTop: 10 }}>
								<Typography.Text strong>{intl.get('pages.Channel.EditChannel.private-deployment-address')}</Typography.Text>
							</div>
							<Input
								name='base_url'
								placeholder={`${intl.get(
									'pages.Channel.EditChannel.please-enter-the-private-deployment-address'
								)}https://fastgpt.run/api/openapi`}
								onChange={(value) => {
									handleInputChange('base_url', value);
								}}
								value={inputs.base_url}
								autoComplete='new-password'
							/>
						</>
					)}
				</Spin>
			</SideSheet>
		</>
	);
};

export default EditChannel;
