import { intl } from '../lang';
const paySelect = [
	{
		Label: 'EPAY',
		ID: 'EPAY',
	},
	{
		Label: 'STRIPE',
		ID: 'STRIPE',
	},
];

const paySettingConfig = [
	{
		payID: 'EPAY',
		formConfig: [
			{
				type: 'Input',
				name: 'api_location',
				label: `${intl.get('helpers.pay-setting.pay-location')}`,
			},
			{
				type: 'Input',
				name: 'merchant_id',
				label: `${intl.get('helpers.pay-setting.merchant-id')}`,
			},
			{
				type: 'Input',
				name: 'merchant_key',
				label: `${intl.get('helpers.pay-setting.merchant-key')}`,
			},
			{
				type: 'Input',
				name: 'price',
				label: `${intl.get('helpers.pay-setting.pay-price')}`,
				placeholder: `${intl.get('helpers.pay-setting.for-example')}${intl.get(
					'helpers.pay-setting.7-that-is-7-RMB-or-7-dollars'
				)}`,
			},
		],
	},
	{
		payID: 'STRIPE',
		formConfig: [
			{
				type: 'Input',
				name: 'api_location',
				label: `${intl.get('helpers.pay-setting.pay-location')}`,
			},
			{
				type: 'Input',
				name: 'merchant_id',
				label: `${intl.get('helpers.pay-setting.merchant-id')}`,
			},
			{
				type: 'Input',
				name: 'merchant_key',
				label: `${intl.get('helpers.pay-setting.merchant-key')}`,
			},
			{
				type: 'Input',
				name: 'price',
				label: `${intl.get('helpers.pay-setting.pay-price')}`,
				placeholder: `${intl.get('helpers.pay-setting.for-example')}${intl.get(
					'helpers.pay-setting.7-that-is-7-RMB-or-7-dollars'
				)}`,
			},
		],
	},
];

/**
 * 获取所有支付渠道
 * @returns
 */
export const getPaySelect = () => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				success: true,
				message: '',
				data: paySelect,
			});
		}, 1500);
	});
};

/**
 * 根据支付渠道id获取支付渠道信息
 * @param {String} payID 支付渠道id
 * @returns
 */
export const getPaySettingConfig = (payID) => {
	const find = paySettingConfig.find((config) => config.payID === payID);
	if (!find) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({
					success: false,
					message: payID + `${intl.get('helpers.pay-setting.inexistence')}`,
					data: null,
				});
			}, 500);
		});
	}
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				success: true,
				message: '',
				data: find,
			});
		}, 1500);
	});
};
