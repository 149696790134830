import React, { useEffect, useState } from 'react';
import { API, isMobile, showError, showSuccess } from '../../helpers';
import { renderQuotaWithPrompt } from '../../helpers/render';
import Title from '@douyinfe/semi-ui/lib/es/typography/title';
import { SideSheet, Space, Button, Spin, Input, Typography, Select, Divider } from '@douyinfe/semi-ui';
import { intl } from '../../lang';
const EditUser = (props) => {
	const userId = props.editingUser.id;
	const [loading, setLoading] = useState(true);
	const [inputs, setInputs] = useState({
		username: '',
		display_name: '',
		password: '',
		github_id: '',
		wechat_id: '',
		email: '',
		quota: 0,
		group: 'default',
	});
	const [groupOptions, setGroupOptions] = useState([]);
	const { username, display_name, password, github_id, wechat_id, email, quota } = inputs;
	const handleInputChange = (name, value) => {
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	};
	const fetchGroups = async () => {
		try {
			let res = await API.get(`/api/group/`);
			setGroupOptions(
				res.data.data.map((group) => ({
					label: group,
					value: group,
				}))
			);
		} catch (error) {
			showError(error.message);
		}
	};
	const handleCancel = () => {
		props.handleClose();
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const loadUser = async () => {
		setLoading(true);
		let res = undefined;
		if (userId) {
			res = await API.get(`/api/user/${userId}`);
		} else {
			res = await API.get(`/api/user/self`);
		}
		const { success, message, data } = res.data;
		if (success) {
			data.password = '';
			setInputs(data);
		} else {
			showError(message);
		}
		setLoading(false);
	};

	useEffect(() => {
		loadUser().then();
		if (userId) {
			fetchGroups().then();
		}
	}, [props.editingUser.id]);

	const submit = async () => {
		setLoading(true);
		let res = undefined;
		if (userId) {
			let data = { ...inputs, id: parseInt(userId) };
			if (typeof data.quota === 'string') {
				data.quota = parseInt(data.quota);
			}
			res = await API.put(`/api/user/`, data);
		} else {
			res = await API.put(`/api/user/self`, inputs);
		}
		const { success, message } = res.data;
		if (success) {
			showSuccess(`${intl.get('pages.User.EditUser.updated-successfully')}`);
			props.refresh();
			props.handleClose();
		} else {
			showError(message);
		}
		setLoading(false);
	};

	return (
		<>
			<SideSheet
				placement={'right'}
				title={<Title level={3}>{intl.get('pages.User.EditUser.edit-user')}</Title>}
				headerStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
				bodyStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
				visible={props.visible}
				footer={
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Space>
							<Button theme='solid' size={'large'} onClick={submit}>
								{intl.get('pages.User.EditUser.submit')}
							</Button>
							<Button theme='solid' size={'large'} type={'tertiary'} onClick={handleCancel}>
								{intl.get('pages.User.EditUser.cancel')}
							</Button>
						</Space>
					</div>
				}
				closeIcon={null}
				onCancel={() => handleCancel()}
				width={isMobile() ? '100%' : 600}
			>
				<Spin spinning={loading}>
					<div style={{ marginTop: 20 }}>
						<Typography.Text>{intl.get('pages.User.EditUser.username')}</Typography.Text>
					</div>
					<Input
						label={intl.get('pages.User.EditUser.username')}
						name='username'
						placeholder={intl.get('pages.User.EditUser.enter-username')}
						onChange={(value) => handleInputChange('username', value)}
						value={username}
						autoComplete='new-password'
					/>
					<div style={{ marginTop: 20 }}>
						<Typography.Text>{intl.get('pages.User.EditUser.password')}</Typography.Text>
					</div>
					<Input
						label={intl.get('pages.User.EditUser.password')}
						name='password'
						type={'password'}
						placeholder={intl.get('pages.User.EditUser.enter-your-password')}
						onChange={(value) => handleInputChange('password', value)}
						value={password}
						autoComplete='new-password'
					/>
					<div style={{ marginTop: 20 }}>
						<Typography.Text>{intl.get('pages.User.EditUser.display-name')}</Typography.Text>
					</div>
					<Input
						label={intl.get('pages.User.EditUser.display-name')}
						name='display_name'
						placeholder={intl.get('pages.User.EditUser.please-enter-a-display-name')}
						onChange={(value) => handleInputChange('display_name', value)}
						value={display_name}
						autoComplete='new-password'
					/>
					{userId && (
						<>
							<div style={{ marginTop: 20 }}>
								<Typography.Text>{intl.get('pages.User.EditUser.group')}</Typography.Text>
							</div>
							<Select
								placeholder={intl.get('pages.User.EditUser.please-select-group')}
								name='group'
								fluid
								search
								selection
								allowAdditions
								additionLabel={intl.get('pages.User.EditUser.add-a-new-group')}
								onChange={(value) => handleInputChange('group', value)}
								value={inputs.group}
								autoComplete='new-password'
								optionList={groupOptions}
							/>
							<div style={{ marginTop: 20 }}>
								<Typography.Text>{`${intl.get('pages.User.EditUser.remnant-quota')}${renderQuotaWithPrompt(
									quota
								)}`}</Typography.Text>
							</div>
							<Input
								name='quota'
								placeholder={intl.get('pages.User.EditUser.please-enter-a-new-remnant-amount')}
								onChange={(value) => handleInputChange('quota', value)}
								value={quota}
								type={'number'}
								autoComplete='new-password'
							/>
						</>
					)}
					<Divider style={{ marginTop: 20 }}>{intl.get('pages.User.EditUser.not-modified')}</Divider>
					<div style={{ marginTop: 20 }}>
						<Typography.Text>{intl.get('pages.User.EditUser.bind-GitHub-account')}</Typography.Text>
					</div>
					<Input
						name='github_id'
						value={github_id}
						autoComplete='new-password'
						placeholder={intl.get('pages.User.EditUser.only-read')}
						readonly
					/>
					<div style={{ marginTop: 20 }}>
						<Typography.Text>{intl.get('pages.User.EditUser.bind-WeChat-account')}</Typography.Text>
					</div>
					<Input
						name='wechat_id'
						value={wechat_id}
						autoComplete='new-password'
						placeholder={intl.get('pages.User.EditUser.only-read')}
						readonly
					/>
					<div style={{ marginTop: 20 }}>
						<Typography.Text>{intl.get('pages.User.EditUser.bind-email-account')}</Typography.Text>
					</div>
					<Input
						name='email'
						value={email}
						autoComplete='new-password'
						placeholder={intl.get('pages.User.EditUser.only-read')}
						readonly
					/>
				</Spin>
			</SideSheet>
		</>
	);
};

export default EditUser;
