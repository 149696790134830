import React, { useEffect, useState } from 'react';
import { Divider, Form, Grid, Header, Message } from 'semantic-ui-react';
import { API, showError } from '../helpers';
import { Select } from '@douyinfe/semi-ui';

import styled from 'styled-components';

import { intl } from '../lang';
const HomePageContext = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	padding: 8px;
	align-items: center;
	.header {
		display: flex;
		gap: 8px;
		width: 100%;
		align-items: center;
		.label {
			display: block;
			color: rgba(0, 0, 0, 0.87);
			font-size: 0.92857143em;
			font-weight: 700;
			text-transform: none;
		}
		.lang {
			.semi-select {
				width: 120px;
			}
		}
	}
	.field {
		width: 100%;
	}
`;
const langIncludes = ['HomePageContent', 'About', 'ApiDocPageContent'];
const OtherSetting = () => {
	let [inputs, setInputs] = useState({
		Footer: '',
		Notice: '',
		About: '',
		ApiDocPageContent: '',
		SystemName: '',
		Logo: '',
		HomePageContent: '',
	});
	let [loading, setLoading] = useState(false);

	const getOptions = async () => {
		const res = await API.get('/api/option/');
		const { success, message, data } = res.data;
		if (success) {
			let newInputs = {};
			data.forEach((item) => {
				if (item.key in inputs) {
					newInputs[item.key] = item.value;
				}
			});
			const homeContext = data.find((item) => item.key === `HomePageContent:${homeLang}`);
			newInputs[`HomePageContent`] = homeContext ? homeContext.value : '';
			const aboutContext = data.find((item) => item.key === `About:${aboutLang}`);
			newInputs[`About`] = aboutContext ? aboutContext.value : '';
			const apiContext = data.find((item) => item.key === `ApiDocPageContent:${apiLang}`);
			newInputs[`ApiDocPageContent`] = apiContext ? apiContext.value : '';
			// 单独处理首页配置和关于配置
			setInputs(newInputs);
		} else {
			showError(message);
		}
	};

	useEffect(() => {
		getOptions().then();
	}, []);
	const updateOption = async (key, value) => {
		setLoading(true);
		let updateKey = key;
		if (langIncludes.includes(updateKey)) {
			if (updateKey === 'HomePageContent') {
				updateKey = updateKey + ':' + homeLang;
			} else if (updateKey === 'About') {
				updateKey = updateKey + ':' + aboutLang;
			} else if (updateKey === 'ApiDocPageContent') {
				updateKey = updateKey + ':' + apiLang;
			}
		}
		const res = await API.put('/api/option/', {
			key: updateKey,
			value,
		});
		const { success, message } = res.data;
		if (success) {
			setInputs((inputs) => ({ ...inputs, [key]: value }));
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const handleInputChange = async (e, { name, value }) => {
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	};

	const submitNotice = async () => {
		await updateOption('Notice', inputs.Notice);
	};

	const submitFooter = async () => {
		await updateOption('Footer', inputs.Footer);
	};

	const submitSystemName = async () => {
		await updateOption('SystemName', inputs.SystemName);
	};

	const submitLogo = async () => {
		await updateOption('Logo', inputs.Logo);
	};

	const submitAbout = async () => {
		await updateOption('About', inputs.About);
	};

	const submitAPI = async () => {
		await updateOption('ApiDocPageContent', inputs.ApiDocPageContent);
	};

	const submitOption = async (key) => {
		await updateOption(key, inputs[key]);
	};

	// 语言
	const [homeLang, setHomeLang] = useState(localStorage.getItem('lang'));
	const [aboutLang, setAboutLang] = useState(localStorage.getItem('lang'));
	const [apiLang, setApiLang] = useState(localStorage.getItem('lang'));
	useEffect(() => {
		getOptions();
	}, [homeLang, aboutLang, apiLang]);
	return (
		<Grid columns={1}>
			<Grid.Column>
				<Form loading={loading}>
					<Header as='h3'>{intl.get('components.OtherSetting.common-setting')}</Header>
					{/*<Form.Button onClick={checkUpdate}>检查更新</Form.Button>*/}
					<Form.Group widths='equal'>
						<Form.TextArea
							label={intl.get('components.OtherSetting.notice')}
							placeholder={intl.get('components.OtherSetting.enter-the-new-announcement-here')}
							value={inputs.Notice}
							name='Notice'
							onChange={handleInputChange}
							style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
						/>
					</Form.Group>
					<Form.Button onClick={submitNotice}>{intl.get('components.OtherSetting.save-notice')}</Form.Button>
					<Divider />
					<Header as='h3'>{intl.get('components.OtherSetting.personalized-setting')}</Header>
					<Form.Group widths='equal'>
						<Form.Input
							label={intl.get('components.OtherSetting.system-name')}
							placeholder={intl.get('components.OtherSetting.enter-system-name-here')}
							value={inputs.SystemName}
							name='SystemName'
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Button onClick={submitSystemName}>{intl.get('components.OtherSetting.save-system-name')}</Form.Button>
					<Form.Group widths='equal'>
						<Form.Input
							label={intl.get('components.OtherSetting.logo-address')}
							placeholder={intl.get('components.OtherSetting.enter-the-logo-image-address-here')}
							value={inputs.Logo}
							name='Logo'
							type='url'
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Button onClick={submitLogo}>{intl.get('components.OtherSetting.logo-setting')}</Form.Button>
					<Form.Group widths='equal'>
						<HomePageContext>
							<div className='header'>
								<span className='label'>{intl.get('components.OtherSetting.home-page-content')}</span>
								<span className='lang'>
									<Select
										value={homeLang}
										onChange={(value) => {
											setHomeLang(value);
										}}
									>
										<Select.Option value='zh'>简体中文</Select.Option>
										<Select.Option value='zh-tw'>繁體中文</Select.Option>
										<Select.Option value='en'>English</Select.Option>
										<Select.Option value='fr'>Französisch</Select.Option>
										<Select.Option value='de'>Deutsch</Select.Option>
										<Select.Option value='ja'>日本語</Select.Option>
										<Select.Option value='ko'>한국어</Select.Option>
										<Select.Option value='es'>Español</Select.Option>
									</Select>
								</span>
							</div>
							<Form.TextArea
								placeholder={intl.get('components.OtherSetting.enter-the-home-page-content-here')}
								value={inputs.HomePageContent}
								name='HomePageContent'
								onChange={handleInputChange}
								style={{ width: '100%', minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
							/>
						</HomePageContext>
					</Form.Group>
					<Form.Button onClick={() => submitOption('HomePageContent')}>
						{intl.get('components.OtherSetting.save-home-page-content')}
					</Form.Button>
					<Form.Group widths='equal'>
						<HomePageContext>
							<div className='header'>
								<span className='label'>{intl.get('components.OtherSetting.about')}</span>
								<span className='lang'>
									<Select
										value={aboutLang}
										onChange={(value) => {
											setAboutLang(value);
										}}
									>
										<Select.Option value='zh'>简体中文</Select.Option>
										<Select.Option value='zh-tw'>繁體中文</Select.Option>
										<Select.Option value='en'>English</Select.Option>
										<Select.Option value='fr'>Französisch</Select.Option>
										<Select.Option value='de'>Deutsch</Select.Option>
										<Select.Option value='ja'>日本語</Select.Option>
										<Select.Option value='ko'>한국어</Select.Option>
										<Select.Option value='es'>Español</Select.Option>
									</Select>
								</span>
							</div>
							<Form.TextArea
								placeholder={intl.get('components.OtherSetting.enter-the-new-about-content-here')}
								value={inputs.About}
								name='About'
								onChange={handleInputChange}
								style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
							/>
						</HomePageContext>
					</Form.Group>
					<Form.Button onClick={submitAbout}>{intl.get('components.OtherSetting.save-about')}</Form.Button>

					<Form.Group widths='equal'>
						<HomePageContext>
							<div className='header'>
								<span className='label'>API</span>
								<span className='lang'>
									<Select
										value={apiLang}
										onChange={(value) => {
											setApiLang(value);
										}}
									>
										<Select.Option value='zh'>简体中文</Select.Option>
										<Select.Option value='zh-tw'>繁體中文</Select.Option>
										<Select.Option value='en'>English</Select.Option>
										<Select.Option value='fr'>Französisch</Select.Option>
										<Select.Option value='de'>Deutsch</Select.Option>
										<Select.Option value='ja'>日本語</Select.Option>
										<Select.Option value='ko'>한국어</Select.Option>
										<Select.Option value='es'>Español</Select.Option>
									</Select>
								</span>
							</div>
							<Form.TextArea
								placeholder={intl.get('components.OtherSetting.api-placeholder')}
								value={inputs.ApiDocPageContent}
								name='ApiDocPageContent'
								onChange={handleInputChange}
								style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
							/>
						</HomePageContext>
					</Form.Group>
					<Form.Button onClick={submitAPI}>{intl.get('components.OtherSetting.save-api')}</Form.Button>
					<Message>{intl.get('components.OtherSetting.message-text')}</Message>
					<Form.Group widths='equal'>
						<Form.Input
							label={intl.get('components.OtherSetting.footer')}
							placeholder={intl.get('components.OtherSetting.enter-a-new-footer-here')}
							value={inputs.Footer}
							name='Footer'
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Button onClick={submitFooter}>{intl.get('components.OtherSetting.save-footer-setting')}</Form.Button>
				</Form>
			</Grid.Column>
		</Grid>
	);
};

export default OtherSetting;
