import React from 'react';
import RedemptionsTable from '../../components/RedemptionsTable';
import { Layout } from '@douyinfe/semi-ui';
import { intl } from '../../lang';
const Redemption = () => (
	<>
		<Layout>
			<Layout.Header>
				<h3>{intl.get('pages.Redemption.index.manage-ode')}</h3>
			</Layout.Header>
			<Layout.Content>
				<RedemptionsTable />
			</Layout.Content>
		</Layout>
	</>
);

export default Redemption;
