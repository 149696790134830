import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react';
import { API, showError, showInfo, showSuccess } from '../helpers';
import Turnstile from 'react-turnstile';

import { intl } from '../lang';

const PasswordResetForm = () => {
	const [inputs, setInputs] = useState({
		email: '',
	});
	const { email } = inputs;

	const [loading, setLoading] = useState(false);
	const [turnstileEnabled, setTurnstileEnabled] = useState(false);
	const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
	const [turnstileToken, setTurnstileToken] = useState('');
	const [disableButton, setDisableButton] = useState(false);
	const [countdown, setCountdown] = useState(30);

	useEffect(() => {
		let countdownInterval = null;
		if (disableButton && countdown > 0) {
			countdownInterval = setInterval(() => {
				setCountdown(countdown - 1);
			}, 1000);
		} else if (countdown === 0) {
			setDisableButton(false);
			setCountdown(30);
		}
		return () => clearInterval(countdownInterval);
	}, [disableButton, countdown]);

	function handleChange(e) {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	}

	async function handleSubmit(e) {
		setDisableButton(true);
		if (!email) return;
		if (turnstileEnabled && turnstileToken === '') {
			showInfo(`${intl.get('components.PasswordResetForm.please-try-again-in-a-few-seconds')}`);
			return;
		}
		setLoading(true);
		const res = await API.get(`/api/reset_password?email=${email}&turnstile=${turnstileToken}`);
		const { success, message } = res.data;
		if (success) {
			showSuccess(`${intl.get('components.PasswordResetForm.reset-email-sent-successfully')}`);
			setInputs({ ...inputs, email: '' });
		} else {
			showError(message);
		}
		setLoading(false);
	}

	return (
		<Grid textAlign='center' style={{ marginTop: '48px' }}>
			<Grid.Column style={{ maxWidth: 450 }}>
				<Header as='h2' color='' textAlign='center'>
					<Image src='/logo.png' /> {intl.get('components.PasswordResetForm.reset-password')}
				</Header>
				<Form size='large'>
					<Segment>
						<Form.Input
							fluid
							icon='mail'
							iconPosition='left'
							placeholder={intl.get('components.PasswordResetForm.email-address')}
							name='email'
							value={email}
							onChange={handleChange}
						/>
						{turnstileEnabled ? (
							<Turnstile
								sitekey={turnstileSiteKey}
								onVerify={(token) => {
									setTurnstileToken(token);
								}}
							/>
						) : (
							<></>
						)}
						<Button color='green' fluid size='large' onClick={handleSubmit} loading={loading} disabled={disableButton}>
							{disableButton
								? `${intl.get('components.PasswordResetForm.retry')} (${countdown})`
								: `${intl.get('components.PasswordResetForm.submit')}`}
						</Button>
					</Segment>
				</Form>
			</Grid.Column>
		</Grid>
	);
};

export default PasswordResetForm;
