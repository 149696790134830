import styled from 'styled-components';

const PrivacyWarpper = styled.div`
	display: flex;
	justify-content: center;
	padding: 32px;
	.paragraph-container {
		display: flex;
		flex-direction: column;
		gap: 32px;
		width: 800px;
		.item {
			.paragraph {
				.link {
					color: rgb(3, 102, 214);
				}
			}
		}
	}
`;

const Privacy = () => {
	return (
		<PrivacyWarpper>
			<div className='paragraph-container'>
				<div className='item'>
					<h2 className='title'>Last updated: October 22, 2023</h2>
					<p className='paragraph'>
						This privacy policy ("Policy") describes how OPENAI365 LLC, operator of www.doublegpt.io ("DoubleGPT", "we", "us", or
						"our"), collects, uses, shares, and protects information about you when you use our website, products, and services
						(collectively, the "Services").
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Our Services</h2>
					<p className='paragraph'>
						DoubleGPT provides a service that allows for double the invocation of GPT-4 and other advanced AI models（including
						but not limited to text generation and image generation services）, accessible via{' '}
						<a href='https://www.doublegpt.io'> https://www.doublegpt.io</a> and its API endpoints.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Information We Collect</h2>
					<p className='paragraph'>
						When you visit our website or use our Services, we may collect certain information automatically from your device.
						This information may include your IP address, device type, unique device identification numbers, browser-type, broad
						geographic location, and other technical information.
					</p>
					<p className='paragraph'>
						We also keep track of your interactions with our website, such as pages accessed and links clicked. We use this
						information for our internal analytics and to improve the quality and relevance of our Services.
					</p>
					<p className='paragraph'>
						If you create an account on our website, we will collect certain information from you, including your email address.
						This ensures your ability to access your account and enables us to provide support when needed.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Information We Do Not Collect</h2>
					<p className='paragraph'>
						We do not collect sensitive information from you, such as your race, ethnicity, political opinions, religion, or
						health information.
					</p>
					<p className='paragraph'>
						We promise not to gather, store or sell your interaction inputs and outputs with our AI Services.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Sharing of Information</h2>
					<p className='paragraph'>
						We do not sell, trade, or otherwise share your personal information with third parties beyond what described in this
						Policy.
					</p>
					<p className='paragraph'>
						Service providers. We may share personal information with service providers for the purpose of maintaining and
						improving our Services, including sending emails, processing payments, and providing customer support.
					</p>
					<p className='paragraph'>
						Analytics service providers. We may share anonymized usage data with analytics providers who assist us in
						understanding the usage of our Service. For example, Google Analytics.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Third Party Services</h2>
					<p className='paragraph'>
						We use third-party services, such as APIs, to maintain and improve our services. For example, some AI functionality
						may call the OpenAI API.
					</p>
					<p className='paragraph'>
						Regarding the generated assets by our AI image service, you grant rights to OPENAI365 LLC while maintaining the
						ownership as elaborated in the following paragraphs:
					</p>
					<p className='paragraph'>
						We use site monitoring tools to collect anonymized information about usage. These tools may collect your IP address,
						device type, browser type, operating system, and other technical information.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Security</h2>
					<p className='paragraph'>
						We use reasonable measures to protect your information. Data is securely stored on cloud servers. However, no security
						measure is perfect, therefore we cannot provide an absolute guarantee for the security of your personal information.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Data Retention</h2>
					<p className='paragraph'>
						We retain your information as long as your account is active or needed to provide you with our services. If you wish
						to remove your account, we will delete your personal information from our records within 30 days. Please contact us at
						support@doublegpt.io for account deletion requests.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Changes to Our Privacy Policy</h2>
					<p className='paragraph'>
						We reserve the right to update this Policy from time to time. When changes are made, we will post the revised Policy
						on our website and update the "Last updated" date. We encourage you to regularly check this Policy to stay updated
						about our practices and the options available to you.
					</p>
					<p className='paragraph'>
						If you have any questions about this Policy or any privacy matters, feel free to contact us at support@doublegpt.io`.
					</p>
					<p>Thank you for trusting us with your AI needs.</p>
				</div>
			</div>
		</PrivacyWarpper>
	);
};

export default Privacy;
