import { initVChartSemiTheme } from '@visactor/vchart-semi-theme';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import { UserProvider } from './context/User';
import 'react-toastify/dist/ReactToastify.css';
import { StatusProvider } from './context/Status';
import './lang';
import './styles/index.css'
import './index.css'

// initialization
initVChartSemiTheme({
	isWatchingThemeSwitch: true,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<StatusProvider>
		<UserProvider>
			<App />
		</UserProvider>
	</StatusProvider>
);
