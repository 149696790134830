import { intl } from '../lang';

export const CHANNEL_OPTIONS = [
	{ key: 1, text: 'OpenAI', value: 1, color: 'green', label: `${intl.get('constants.channel.open-AI')}` },
	{ key: 2, text: 'Midjourney Proxy', value: 2, color: 'light-blue', label: `${intl.get('constants.channel.midjourney-proxy')}`},
	{ key: 5, text: 'Midjourney Proxy Plus', value: 5, color: 'blue', label: `${intl.get('constants.channel.midjourney-proxy-plus')}`},
	{ key: 4, text: 'Ollama', value: 4, color: 'grey', label: `${intl.get('constants.channel.ollama')}`},
	{ key: 14, text: 'Anthropic Claude', value: 14, color: 'black', label: `${intl.get('constants.channel.anthropic-claude')}`},
	{ key: 3, text: 'Azure OpenAI', value: 3, color: 'olive', label: `${intl.get('constants.channel.azure-open-AI')}`},
	{ key: 11, text: 'Google PaLM2', value: 11, color: 'orange', label: `${intl.get('constants.channel.google-paL-m2')}`},
	{ key: 24, text: 'Google Gemini', value: 24, color: 'orange', label: `${intl.get('constants.channel.google-gemini')}`},
	{
		key: 15,
		text: `${intl.get('constants.channel.baidu-wenxin')}`,
		value: 15,
		color: 'blue',
		label: `${intl.get('constants.channel.baidu-wenxin')}`,
	},
	{
		key: 17,
		text: `${intl.get('constants.channel.ali-qianwen')}`,
		value: 17,
		color: 'orange',
		label: `${intl.get('constants.channel.ali-qianwen')}`,
	},
	{
		key: 18,
		text: `${intl.get('constants.channel.xinghuo-xf')}`,
		value: 18,
		color: 'blue',
		label: `${intl.get('constants.channel.xinghuo-xf')}`,
	},
	{
		key: 16,
		text: `${intl.get('constants.channel.zhipu-ai')} ChatGLM`,
		value: 16,
		color: 'violet',
		label: `${intl.get('constants.channel.zhipu-ai')} ChatGLM`,
	},
	{
		key: 19,
		text: `360 ${intl.get('constants.channel.zhi-brain')}`,
		value: 19,
		color: 'blue',
		label: `360 ${intl.get('constants.channel.zhi-brain')}`,
	},
	{
		key: 23,
		text: `${intl.get('constants.channel.tencent-hunyuan')}`,
		value: 23,
		color: 'teal',
		label: `${intl.get('constants.channel.tencent-hunyuan')}`,
	},
	{
		key: 8,
		text: `${intl.get('constants.channel.repository')}FastGPT`,
		value: 8,
		color: 'pink',
		label: `${intl.get('constants.channel.repository')}FastGPT`,
	},
	{
		key: 22,
		text: `${intl.get('constants.channel.repository')}FastGPT`,
		value: 22,
		color: 'blue',
		label: `${intl.get('constants.channel.repository')}FastGPT`,
	},
	{
		key: 21,
		text: `${intl.get('constants.channel.repository')}AI Proxy`,
		value: 21,
		color: 'purple',
		label: `${intl.get('constants.channel.repository')}AI Proxy`,
	},
];
