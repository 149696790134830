import React from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import { intl } from '../lang';
const Loading = ({ prompt: name = 'page' }) => {
	return (
		<Segment style={{ height: 100 }}>
			<Dimmer active inverted>
				<Loader indeterminate>{intl.get('components.Loading.load') + name + intl.get('components.Loading.ing')}</Loader>
			</Dimmer>
		</Segment>
	);
};

export default Loading;
