import styled from 'styled-components';

const TermsWarpper = styled.div`
	display: flex;
	justify-content: center;
	padding: 32px;
	.paragraph-container {
		display: flex;
		flex-direction: column;
		gap: 32px;
		width: 800px;
		.item {
			.paragraph {
				.link {
					color: rgb(3, 102, 214);
				}
			}
		}
	}
`;

const Terms = () => {
	return (
		<TermsWarpper>
			<div className='paragraph-container'>
				<div className='item'>
					<h2 className='title'>Last updated: October 22, 2023</h2>
					<p className='paragraph'>
						Please read these Terms of Service ("Terms") carefully before using the www.doublegpt.io website (the "Service")
						operated by OPENAI365 LLC ("us", "we", or "our").
					</p>
					<p className='paragraph'>
						Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These
						Terms apply to all visitors, users, and others who access or use the Service.
					</p>
					<p className='paragraph'>
						By accessing or using the Service you agree to be bound by these Terms. OPENAI365LLC reserves the right, in its sole
						discretion, to restrict, suspend, or terminate your access to and use of the Service.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Our Services</h2>
					<p className='paragraph'>
						DoubleGPT provides a service that allows for double the invocation of GPT-4 and other advanced AI models (including
						but not limited to text generation and image generation services). This is available via{' '}
						<a href='https://www.doublegpt.io'>https://www.doublegpt.io</a> and associated API endpoints. Additional future
						services may include AI chip supply, AI model training, deployment of open-source AI models, and others.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Acceptable Use</h2>
					<p className='paragraph'>
						You must be at least 13 years old to use the Service. If you are under 18 you must have your parent or legal
						guardian’s permission to use the Service.
					</p>
					<p className='paragraph'>
						<span className='link'>1.</span>You agree not to share, transfer, sell, or in any way abuse your account privileges.
					</p>
					<p className='paragraph'>
						<span className='link'>2.</span>You are responsible for all activities that occur under your account.
					</p>
					<p className='paragraph'>
						<span className='link'>3.</span>You are required to maintain a positive account balance to continue to use the
						services. The account balance is used on a pay-as-you-go basis, with charges incurred for AI resource usage.
					</p>
					<p className='paragraph'>
						<span className='link'>4.</span>You are entitled to an unconditional 14-day refund policy. Within this period, 
						you can apply for a full refund without any conditions. After the 14-day period, all sales are considered final 
						and non-refundable, except in cases where Force Majeure prevents the company from continuing to provide services.
					</p>
					<p className='paragraph'>
						<span className='link'>5.</span>You agree to comply with all applicable laws, rules, and regulations in your use of
						the services and will not engage in or use the service for any illegal activities
					</p>
					<p className='paragraph'>
						<span className='link'>6.</span>You agree not to conduct any systematic or automated data collection activities on
						or in relation to our Service.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>User Data</h2>
					<p className='paragraph'>
						We respect your privacy and commit ourselves to protect it to the maximum extent according to applicable laws and
						regulations.
					</p>
					<p className='paragraph'>
						<span className='link'>1.</span>We collect the necessary user information required for the operation of our service,
						such as IP addresses, emails, service usage data, and API access log.
					</p>
					<p className='paragraph'>
						<span className='link'>2.</span>The data usage is primarily intended for anti-fraud, preventing network attacks,
						service usage calculation, and ensuring the smooth operation of our services.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Intellectual Property</h2>
					<p className='paragraph'>
						The AI models deployed by our services are provided by third parties like OpenAI, and the company does not currently
						possess the ability to produce proprietary AI models.
					</p>
					<p className='paragraph'>
						Regarding the generated assets by our AI image service, you grant rights to OPENAI365 LLC while maintaining the
						ownership as elaborated in the following paragraphs:
					</p>
					<h5>Rights You give to OPENAI365 LLC</h5>
					<p className='paragraph'>
						By using the Services, You grant to OPENAI365 LLC, its successors, and assigns a perpetual, worldwide, non-exclusive,
						sublicensable no-charge, royalty-free, irrevocable copyright license to reproduce, prepare Derivative Works of,
						publicly display, publicly perform, sublicense, and distribute text, and image prompts You input into the Services, or
						Assets produced by the service at Your direction. This license survives termination of this Agreement by any party,
						for any reason.
					</p>
					<h5>Your Rights</h5>
					<p className='paragraph'>
						Subject to the above license, You own all Assets You create with the Services, provided they were created in
						accordance with this Agreement. This does not apply to upscaling the images of others, which images remain owned by
						the original Asset creators.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Third-party Links or Integration</h2>
					<p className='paragraph'>
						Our Service uses Google Analytics and Stripe. Google Analytics is used for site activity and data tracking, and Stripe
						is used for payment processing for which user data such as email, address, and IP might be required for anti-fraud
						validation and facilitating successful transactions.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Termination</h2>
					<p className='paragraph'>
						We reserve the right to block or suspend a user's account when we detect any of the following activities:
					</p>
					<p className='paragraph'>
						<span className='link'>1.</span>Abusing loopholes in the platform to illegitimately gain rewards.
					</p>
					<p className='paragraph'>
						<span className='link'>2.</span>Malicious attacks on our website or services leading to abnormalities or losses.
					</p>
					<p className='paragraph'>
						<span className='link'>3.</span>Unauthorized usage of private or reversed APIs.
					</p>
					<p className='paragraph'>
						<span className='link'>4.</span>Any other behavior that might cause harm to our Service or Users.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Limitations</h2>
					<p className='paragraph'>
						In no event shall OPENAI365 LLC, its affiliates, partners and employed parties be liable for any direct or indirect
						damages or losses related to these Services. The use of DoubleGPT services is at the user's discretion and risk.
					</p>
					<p className='paragraph'>
						Users indemnify and hold harmless OPENAI365 LLC and all associated parties from any claims, liabilities, expenses or
						demands, as a result of misusage, illegal usage or inappropriate usage.
					</p>
				</div>
				<div className='item'>
					<h2 className='title'>Changes</h2>
					<p className='paragraph'>
						OPENAI365 LLC reserves the right to change these terms at any time, and if the changes are significant, we will
						provide a 30-day notice before the new terms take effect. Continuing to use or access our Services following the
						posting of these changes will be considered acceptance of those changes.
					</p>
					<p className='paragraph'>
						For any enquiries or further information, feel free to reach out to us via the contact information provided on our
						website.
					</p>
					<p className='paragraph'>Thank you for choosing us as your AI Service provider.</p>
				</div>
			</div>
		</TermsWarpper>
	);
};

export default Terms;
