import React, { useEffect, useState } from 'react';

import { getFooterHTML } from '../helpers';
import { Layout } from '@douyinfe/semi-ui';

const lang = localStorage.getItem('lang') ?? 'en'

const Footer = () => {
	const [footer, setFooter] = useState(getFooterHTML());
	let remainCheckTimes = 5;

	const loadFooter = () => {
		let footer_html = localStorage.getItem('footer_html');
		if (footer_html) {
			setFooter(footer_html);
		}
	};

	useEffect(() => {
		const timer = setInterval(() => {
			if (remainCheckTimes <= 0) {
				clearInterval(timer);
				return;
			}
			remainCheckTimes--;
			loadFooter();
		}, 200);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Layout>
			<Layout.Content style={{ textAlign: 'center' }}>
				{footer ? (
					<div style={{'margin-bottom': '8px'}} className='custom-footer' dangerouslySetInnerHTML={{ __html: footer }}></div>
				) : (
					<div className='custom-footer'>
						{/* 英语 */}
						{
							lang === 'en' && <>
								<p className='version'>© 2023 OPENAI365 LLC. All rights reserved.</p>
							</>
						}
						{
							lang === 'fr' && <>
								<p className='version'>© 2023 OPENAI365 LLC. Tous droits réservés.</p>
							</>
						}
						{
							lang === 'de' && <>
								<p className='version'>© 2023 OPENAI365 LLC. Alle Rechte vorbehalten.</p>
							</>
						}
						{
							lang === 'es' && <>
								<p className='version'>© 2023 OPENAI365 LLC. Todos los derechos reservados.</p>
							</>
						}
						{
							lang === 'ko' && <>
								<p className='version'>© 2023 OPENAI365 LLC. 모든 권리 보유.</p>
							</>
						}
						{
							lang === 'ja' && <>
								<p className='version'>© 2023年株式会社OPENAI365 全著作権所有</p>
							</>
						}
						{
							lang === 'zh-tw' && <>
								<p className='version'>© 2023 OPENAI365有限公司。版權所有</p>
							</>
						}
						{
							lang === 'zh' && <>
								<p className='version'>© 2023 OPENAI365有限公司.版权所有</p>
							</>
						}
						
					</div>
				)}
			</Layout.Content>
		</Layout>
	);
};

export default Footer;
