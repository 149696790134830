import { showError } from './utils';
import axios from 'axios';

export const API = axios.create({
	baseURL: process.env.NODE_ENV === 'development' ? '/dev-api' : '',
	// baseURL: '',
});

API.interceptors.request.use(
	(config) => {
		if (!config.method) {
			config.method = 'GET';
		}
		config.headers = {
			...config.headers,
			'X-Lang': localStorage.getItem('lang') ?? 'en',
		};
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

API.interceptors.response.use(
	(response) => response,
	(error) => {
		console.log(error)
		// showError(error);
	}
);
