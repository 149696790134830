import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { API, showError, showInfo, showSuccess } from '../../helpers';
import Turnstile from 'react-turnstile';

import { intl } from '../../lang';
const RegisterForm = ({ setCurrent }) => {
	const [inputs, setInputs] = useState({
		username: '',
		password: '',
		password2: '',
		email: '',
		verification_code: '',
	});
	const { username, password, password2 } = inputs;
	const [showEmailVerification, setShowEmailVerification] = useState(false);
	const [turnstileEnabled, setTurnstileEnabled] = useState(false);
	const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
	const [turnstileToken, setTurnstileToken] = useState('');
	const [loading, setLoading] = useState(false);
	let affCode = new URLSearchParams(window.location.search).get('aff');
	if (affCode) {
		localStorage.setItem('aff', affCode);
	}

	useEffect(() => {
		let status = localStorage.getItem('status');
		if (status) {
			status = JSON.parse(status);
			setShowEmailVerification(status.email_verification);
			if (status.turnstile_check) {
				setTurnstileEnabled(true);
				setTurnstileSiteKey(status.turnstile_site_key);
			}
		}
	}, []);

	let navigate = useNavigate();

	function handleChange(e) {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	}

	async function handleSubmit() {
		if (password.length < 8) {
			showInfo(`${intl.get('components.RegisterForm.the-password-must-contain-at-least-8-characters')}`);
			return;
		}
		if (password !== password2) {
			showInfo(`${intl.get('components.RegisterForm.the-two-passwords-are-different')}`);
			return;
		}
		if (username && password) {
			if (turnstileEnabled && turnstileToken === '') {
				showInfo(`${intl.get('components.RegisterForm.please-try-again-in-a-few-seconds')}`);
				return;
			}
			setLoading(true);
			if (!affCode) {
				affCode = localStorage.getItem('aff');
			}
			if (inputs.username.length > 10) {
				showError("The username is too long and should be less than 12 letters in length")
				return
			}
			inputs.aff_code = affCode;
			const res = await API.post(`/api/user/register?turnstile=${turnstileToken}`, inputs);
			const { success, message } = res.data;
			if (success) {
				navigate('/login');
				showSuccess(`${intl.get('components.RegisterForm.registered-successfully')}`);
			} else {
				showError(message);
			}
			setLoading(false);
		}
	}

	const sendVerificationCode = async () => {
		if (inputs.email === '') return;
		if (turnstileEnabled && turnstileToken === '') {
			showInfo(`${intl.get('components.RegisterForm.please-try-again-in-a-few-seconds')}`);
			return;
		}
		setLoading(true);
		const res = await API.get(`/api/verification?email=${inputs.email}&turnstile=${turnstileToken}`);
		const { success, message } = res.data;
		if (success) {
			showSuccess(intl.get('components.RegisterForm.reset-email-sent-successfully'));
		} else {
			showError(message);
		}
		setLoading(false);
	};
	return (
		<Segment>

			<Grid textAlign='center'>
				<Grid.Column style={{ width: 450 }}>
					<Header as='h2' color='' textAlign='center'>
						{intl.get('components.RegisterForm.new-user-registration')}
					</Header>
					<Form size='large'>
						<div>
							<Form.Input
								fluid
								icon='user'
								iconPosition='left'
								placeholder={intl.get('components.RegisterForm.enter-a-user-name-with-a-maximum-of-12-characters')}
								onChange={handleChange}
								name='username'
							/>
							<Form.Input
								fluid
								icon='lock'
								iconPosition='left'
								placeholder={intl.get(
									'components.RegisterForm.enter-a-password-with-a-minimum-of-8-characters-and-a-maximum-of-20-characters'
								)}
								onChange={handleChange}
								name='password'
								type='password'
							/>
							<Form.Input
								fluid
								icon='lock'
								iconPosition='left'
								placeholder={intl.get(
									'components.RegisterForm.enter-a-password-with-a-minimum-of-8-characters-and-a-maximum-of-20-characters'
								)}
								onChange={handleChange}
								name='password2'
								type='password'
							/>
							{showEmailVerification ? (
								<>
									<Form.Input
										fluid
										icon='mail'
										iconPosition='left'
										placeholder={intl.get('components.RegisterForm.enter-your-email-address')}
										onChange={handleChange}
										name='email'
										type='email'
										action={
											<Button onClick={sendVerificationCode} disabled={loading}>
												{intl.get('components.RegisterForm.get-code')}
											</Button>
										}
									/>
									<Form.Input
										fluid
										icon='lock'
										iconPosition='left'
										placeholder={intl.get('components.RegisterForm.enter-verification-code')}
										onChange={handleChange}
										name='verification_code'
									/>
								</>
							) : (
								<></>
							)}
							{turnstileEnabled ? (
								<Turnstile
									sitekey={turnstileSiteKey}
									onVerify={(token) => {
										setTurnstileToken(token);
									}}
								/>
							) : (
								<></>
							)}
							<Button color='green' fluid size='large' onClick={handleSubmit} loading={loading}>
								{intl.get('components.RegisterForm.register')}
							</Button>

						</div>

					</Form>
					
					<Message>
						{intl.get('components.RegisterForm.already-have-an-account')}
						<Link onClick={() => setCurrent(0)} className='btn btn-link'>
							{intl.get('components.RegisterForm.click-here-to-login')}
						</Link>
					</Message>
				</Grid.Column>
			</Grid>
		</Segment>
	)
}

export default RegisterForm
