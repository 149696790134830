import React from 'react';
import UsersTable from '../../components/UsersTable';
import { Layout } from '@douyinfe/semi-ui';
import { intl } from '../../lang';
const User = () => (
	<>
		<Layout>
			<Layout.Header>
				<h3>{intl.get('pages.User.index.manage-user')}</h3>
			</Layout.Header>
			<Layout.Content>
				<UsersTable />
			</Layout.Content>
		</Layout>
	</>
);

export default User;
