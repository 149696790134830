import React from 'react';

import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';
import { Layout } from '@douyinfe/semi-ui';
import SiderBar from '../components/SiderBar';
import { ToastContainer } from 'react-toastify';

const { Sider, Content, Header } = Layout;
const DefaultLayout = (props) => {
	return (
		<Layout>
			<Sider>
				<SiderBar />
			</Sider>
			<Layout>
				<Header>
					<HeaderBar />
				</Header>
				<Content
					style={{
						padding: '24px',
					}}
				>
					{props.children}
				</Content>
				<Layout.Footer>
					<Footer></Footer>
				</Layout.Footer>
			</Layout>
			<ToastContainer />
		</Layout>
	);
};

export default DefaultLayout;
