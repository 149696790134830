import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../../context/User';
import { API, showError, showInfo, showSuccess } from '../../helpers';
import { onGitHubOAuthClicked } from './utils';
import Turnstile from 'react-turnstile';
import { Card, Form, Button, Divider, Modal } from '@douyinfe/semi-ui';
import { IconGithubLogo } from '@douyinfe/semi-icons';
import Title from '@douyinfe/semi-ui/lib/es/typography/title';
import Text from '@douyinfe/semi-ui/lib/es/typography/text';
import { intl } from '../../lang';

const LoginForm = ({ setCurrent }) => {
	const [inputs, setInputs] = useState({
		username: '',
		password: '',
		wechat_verification_code: '',
	});
	const [searchParams] = useSearchParams();
	const { username, password } = inputs;
	const [_, userDispatch] = useContext(UserContext);
	const [turnstileEnabled, setTurnstileEnabled] = useState(false);
	const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
	const [turnstileToken, setTurnstileToken] = useState('');
	let navigate = useNavigate();
	const [status, setStatus] = useState({});

	const loadStatus = useCallback(async () => {
		const res = await API.get('/api/status');
		const { success, data } = res.data;
		if (success) {
			localStorage.setItem('status', JSON.stringify(data));
			let status = localStorage.getItem('status');
			if (status) {
				status = JSON.parse(status);
				setStatus(status);
				if (status.turnstile_check) {
					setTurnstileEnabled(true);
					setTurnstileSiteKey(status.turnstile_site_key);
				}
			}
		} else {
			showError(`${intl.get('App.unable-to-connect-server')}`);
		}
	}, [])
	useEffect(() => {
		loadStatus()
		if (searchParams.get('expired')) {
			showError(`${intl.get('components.LoginForm.no-login-or-login-has-expired')}`);
		}
	}, [loadStatus, searchParams]);
	// const loadStatus = async () => {
	// 	const res = await API.get('/api/status');
	// 	const { success, data } = res.data;
	// 	if (success) {
	// 		localStorage.setItem('status', JSON.stringify(data));
	// 	} else {
	// 		showError(`${intl.get('App.unable-to-connect-server')}`);
	// 	}
	// };

	function handleChange(name, value) {
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	}

	async function handleSubmit(e) {
		if (turnstileEnabled && turnstileToken === '') {
			showInfo(`${intl.get('components.LoginForm.please-try-again-in-a-few-seconds')}`);
			return;
		}
		if (username && password) {
			const res = await API.post(`/api/user/login?turnstile=${turnstileToken}`, {
				username,
				password,
			});
			const { success, message, data } = res.data;
			if (success) {
				userDispatch({ type: 'login', payload: data });
				localStorage.setItem('user', JSON.stringify(data));
				showSuccess(`${intl.get('components.LoginForm.login-successful')}`);
				if (username === 'root' && password === '123456') {
					Modal.error({
						title: `${intl.get('components.LoginForm.you-are-using-the-default-password')}`,
						content: `${intl.get('components.LoginForm.please-change-the-default-password-immediately')}`,
						centered: true,
						okText: intl.get("common.okText"),
						cancelText: intl.get("common.cancelText")
					});
				}
				navigate('/api-keys');
			} else {
				showError(message);
			}
		} else {
			showError(`${intl.get('components.LoginForm.no-password')}!`);
		}
	}
	return (
		<div style={{ justifyContent: 'center', display: 'flex', }}>
			<div style={{ width: 500 }}>
				<Card>
					<Title heading={2} style={{ textAlign: 'center' }}>
						{intl.get('components.LoginForm.login')}
					</Title>
					<Form>
						<Form.Input
							field={'username'}
							label={intl.get('components.LoginForm.username')}
							placeholder={intl.get('components.LoginForm.username')}
							name='username'
							onChange={(value) => handleChange('username', value)}
						/>
						<Form.Input
							field={'password'}
							label={intl.get('components.LoginForm.password')}
							placeholder={intl.get('components.LoginForm.password')}
							name='password'
							type='password'
							onChange={(value) => handleChange('password', value)}
						/>

						<Button
							theme='solid'
							style={{ width: '100%' }}
							type={'primary'}
							size='large'
							htmlType={'submit'}
							onClick={handleSubmit}
						>
							{intl.get('components.login')}
						</Button>
					</Form>
					
					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>


						<Text>
							{intl.get('components.LoginForm.no-account-please-first')}{' '}
							<Link onClick={() => setCurrent(1)}>{intl.get('components.LoginForm.register-account')}</Link>
						</Text>
						<Text>
							{intl.get('components.LoginForm.forgot-password')}{' '}
							<Link onClick={() => setCurrent(2)}>{intl.get('components.LoginForm.click-to-reset')}</Link>
						</Text>

					</div>
					{status.github_oauth || status.wechat_login ? (
						<>
							<Divider margin='12px' align='center'>
								{intl.get('components.LoginForm.third-party-login')}
							</Divider>
							<div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
								{status.github_oauth ? (
									<Button
										type='primary'
										icon={<IconGithubLogo />}
										onClick={() => onGitHubOAuthClicked(status.github_client_id)}
									/>

								) : (
									<></>
								)}
							</div>

						</>
					) : (
						<></>
					)}
				</Card>
				{turnstileEnabled ? (
					<div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
						<Turnstile
							sitekey={turnstileSiteKey}
							onVerify={(token) => {
								setTurnstileToken(token);
							}}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	)
}

export default LoginForm
