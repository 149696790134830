import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Header } from 'semantic-ui-react';
import { API, showError, showInfo, showSuccess } from '../../helpers';
import Turnstile from 'react-turnstile';
import styled from 'styled-components'
import { useStore } from '../../store';

import { intl } from '../../lang';
const PasswordResetFormWarpper = styled.div`
	border: 1px solid ${props => props.isDark ? 'white' : 'rgba(34,36,38,.15)'};
	padding: 8px;
`
const PasswordResetForm = ({ setCurrent }) => {
	const [inputs, setInputs] = useState({
		email: '',
	});
	const { email } = inputs;

	const [loading, setLoading] = useState(false);
	const [turnstileEnabled] = useState(false);
	const [turnstileSiteKey] = useState('');
	const [turnstileToken, setTurnstileToken] = useState('');
	const [disableButton, setDisableButton] = useState(false);
	const [countdown, setCountdown] = useState(30);

	useEffect(() => {
		let countdownInterval = null;
		if (disableButton && countdown > 0) {
			countdownInterval = setInterval(() => {
				setCountdown(countdown - 1);
			}, 1000);
		} else if (countdown === 0) {
			setDisableButton(false);
			setCountdown(30);
		}
		return () => clearInterval(countdownInterval);
	}, [disableButton, countdown]);

	function handleChange(e) {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	}

	async function handleSubmit(e) {
		setDisableButton(true);
		if (!email) return;
		if (turnstileEnabled && turnstileToken === '') {
			showInfo(`${intl.get('components.PasswordResetForm.please-try-again-in-a-few-seconds')}`);
			return;
		}
		setLoading(true);
		const res = await API.get(`/api/reset_password?email=${email}&turnstile=${turnstileToken}`);
		const { success, message } = res.data;
		if (success) {
			showSuccess(`${intl.get('components.PasswordResetForm.reset-email-sent-successfully')}`);
			setInputs({ ...inputs, email: '' });
		} else {
			showError(message);
		}
		setLoading(false);
	}

	const { isDark } = useStore()

	return (
		<PasswordResetFormWarpper isDark={isDark}>
			<Grid textAlign='center'>
				<Grid.Column style={{ width: 450 }}>
					<Header as='h2' color='' textAlign='center'>
						{intl.get('components.PasswordResetForm.reset-password')}
					</Header>
					<Form size='large'>
						<Form.Input
							fluid
							icon='mail'
							iconPosition='left'
							placeholder={intl.get('components.PasswordResetForm.email-address')}
							name='email'
							value={email}
							onChange={handleChange}
						/>
						{turnstileEnabled ? (
							<Turnstile
								sitekey={turnstileSiteKey}
								onVerify={(token) => {
									setTurnstileToken(token);
								}}
							/>
						) : (
							<></>
						)}
						<Button color='green' fluid size='large' onClick={handleSubmit} loading={loading} disabled={disableButton}>
							{disableButton
								? `${intl.get('components.PasswordResetForm.retry')} (${countdown})`
								: `${intl.get('components.PasswordResetForm.submit')}`}
						</Button>
						<div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
						</div>
								
							
						<div style={{ marginTop: '8px' }}>
							<Link onClick={() => setCurrent(0)} className='btn btn-link'>
								{intl.get('components.RegisterForm.click-here-to-login')}
							</Link>
						</div>
					</Form>
				</Grid.Column>
			</Grid>
		</PasswordResetFormWarpper>
	);
};

export default PasswordResetForm;
