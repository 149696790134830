import React, { useEffect, useState } from 'react';
import { Divider, Form, Grid, Header } from 'semantic-ui-react';
import { Checkbox, Space, Button } from '@douyinfe/semi-ui';
import { API, showError, showSuccess, timestamp2string, verifyJSON } from '../helpers';
import { intl } from '../lang';
import styled from 'styled-components';
const CheckboxWarpper = styled.div`
	.semi-checkbox-inner-display {
		background: white;
		color: black;
		box-shadow: inset 0 0 0 1px var(--semi-color-text-3);
	}
`;
const lang = localStorage.getItem('lang') ?? 'en';
const OperationSetting = () => {
	let now = new Date();
	const [drawingSetting, setDrawingSetting] = useState({
		enableDrawingSettings: false,
		allowCallback: false,
	});
	let [inputs, setInputs] = useState({
		QuotaForNewUser: 0,
		QuotaForInviter: 0,
		QuotaForInvitee: 0,
		QuotaRemindThreshold: 0,
		PreConsumedQuota: 0,
		ModelRatio: '',
		ModelPrice: '',
		GroupRatio: '',
		TopupRatio: '',
		TopUpLink: '',
		ChatLink: '',
		QuotaPerUnit: 0,
		AutomaticDisableChannelEnabled: '',
		ChannelDisableThreshold: 0,
		LogConsumeEnabled: '',
		DisplayInCurrencyEnabled: '',
		DisplayTokenStatEnabled: '',
		DrawingEnabled: '',
		DataExportEnabled: '',
		DataExportDefaultTime: 'month',
		DataExportInterval: 5,
		RetryTimes: 0,
	});
	const [originInputs, setOriginInputs] = useState({});
	let [loading, setLoading] = useState(false);
	let [historyTimestamp, setHistoryTimestamp] = useState(timestamp2string(now.getTime() / 1000 - 30 * 24 * 3600)); // a month ago
	// 精确时间选项（小时，天，周）
	const timeOptions = [
		{ key: 'hour', text: `${intl.get('components.OperationSetting.hour')}`, value: 'hour' },
		{ key: 'day', text: `${intl.get('components.OperationSetting.day')}`, value: 'day' },
		{ key: 'week', text: `${intl.get('components.OperationSetting.week')}`, value: 'week' },
		{ key: 'month', text: `${intl.get('components.OperationSetting.month')}`, value: 'month' },
	];
	const getOptions = async () => {
		const res = await API.get('/api/option/');
		const { success, message, data } = res.data;
		if (success) {
			let newInputs = {};
			let drawing = {}
			data.forEach((item) => {
				if (['ModelRatio', 'GroupRatio', 'ModelPrice', 'TopupRatio'].includes(item.key)) {
					try {
						item.value = JSON.stringify(JSON.parse(item.value), null, 2);
					} catch {}
				}
				if(['enableDrawingSettings', 'allowCallback'].includes(item.key)) {
					drawing[item.key] = item.value === 'true'
				}
				newInputs[item.key] = item.value;
			});
			setInputs(newInputs);
			setDrawingSetting(drawing)
			setOriginInputs(newInputs);
		} else {
			showError(message);
		}
	};

	useEffect(() => {
		getOptions().then();
	}, []);

	const updateOption = async (key, value) => {
		setLoading(true);
		if (key.endsWith('Enabled')) {
			value = inputs[key] === 'true' ? 'false' : 'true';
		}
		const res = await API.put('/api/option/', {
			key,
			value,
		});
		const { success, message } = res.data;
		if (success) {
			setInputs((inputs) => ({ ...inputs, [key]: value }));
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const handleInputChange = async (e, { name, value }) => {
		console.log(name, !value);
		if (name.endsWith('Enabled') || name === 'DataExportInterval' || name === 'DataExportDefaultTime') {
			if (name === 'DataExportDefaultTime') {
				localStorage.setItem('data_export_default_time', value);
			}
			await updateOption(name, value);
		} else {
			setInputs((inputs) => ({ ...inputs, [name]: value }));
		}
	};

	const submitConfig = async (group) => {
		// eslint-disable-next-line default-case
		switch (group) {
			case 'monitor':
				if (originInputs['ChannelDisableThreshold'] !== inputs.ChannelDisableThreshold) {
					await updateOption('ChannelDisableThreshold', inputs.ChannelDisableThreshold);
				}
				if (originInputs['QuotaRemindThreshold'] !== inputs.QuotaRemindThreshold) {
					await updateOption('QuotaRemindThreshold', inputs.QuotaRemindThreshold);
				}
				break;
			case 'ratio':
				if (originInputs['ModelRatio'] !== inputs.ModelRatio) {
					if (!verifyJSON(inputs.ModelRatio)) {
						showError(`${intl.get('components.OperationSetting.model-magnification-is-not-a-valid-JSON')}`);
						return;
					}
					await updateOption('ModelRatio', inputs.ModelRatio);
				}
				if (originInputs['GroupRatio'] !== inputs.GroupRatio) {
					if (!verifyJSON(inputs.GroupRatio)) {
						showError(`${intl.get('components.OperationSetting.grouping-magnification-is-not-a-valid-JSON')}`);
						return;
					}
					await updateOption('GroupRatio', inputs.GroupRatio);
				}
				if (originInputs['TopupRatio'] !== inputs.TopupRatio) {
					if (!verifyJSON(inputs.TopupRatio)) {
						showError(`${intl.get('components.OperationSetting.recharge-rate-is-not-a-valid-JSON')}`);
						return;
					}
					await updateOption('TopupRatio', inputs.TopupRatio);
				}
				if (originInputs['ModelPrice'] !== inputs.ModelPrice) {
					if (!verifyJSON(inputs.ModelPrice)) {
						showError(`${intl.get('components.OperationSetting.the-model-fixed-price-is-not-legal-json')}`);
						return;
					}
					await updateOption('ModelPrice', inputs.ModelPrice);
				}
				break;
			case 'quota':
				if (originInputs['QuotaForNewUser'] !== inputs.QuotaForNewUser) {
					await updateOption('QuotaForNewUser', inputs.QuotaForNewUser);
				}
				if (originInputs['QuotaForInvitee'] !== inputs.QuotaForInvitee) {
					await updateOption('QuotaForInvitee', inputs.QuotaForInvitee);
				}
				if (originInputs['QuotaForInviter'] !== inputs.QuotaForInviter) {
					await updateOption('QuotaForInviter', inputs.QuotaForInviter);
				}
				if (originInputs['PreConsumedQuota'] !== inputs.PreConsumedQuota) {
					await updateOption('PreConsumedQuota', inputs.PreConsumedQuota);
				}
				break;
			case 'general':
				if (originInputs['TopUpLink'] !== inputs.TopUpLink) {
					await updateOption('TopUpLink', inputs.TopUpLink);
				}
				if (originInputs['ChatLink'] !== inputs.ChatLink) {
					await updateOption('ChatLink', inputs.ChatLink);
				}
				if (originInputs['QuotaPerUnit'] !== inputs.QuotaPerUnit) {
					await updateOption('QuotaPerUnit', inputs.QuotaPerUnit);
				}
				if (originInputs['RetryTimes'] !== inputs.RetryTimes) {
					await updateOption('RetryTimes', inputs.RetryTimes);
				}
				break;
		}
	};

	const deleteHistoryLogs = async () => {
		console.log(inputs);
		const res = await API.delete(`/api/log/?target_timestamp=${Date.parse(historyTimestamp) / 1000}`);
		const { success, message, data } = res.data;
		if (success) {
			showSuccess(`${data} ${intl.get('components.OperationSetting.log-has-been-cleared')}`);
			return;
		}
		showError(`${intl.get('components.OperationSetting.failed-to-clear-logs')}` + message);
	};
	return (
		<Grid columns={1}>
			<Grid.Column>
				<Form loading={loading}>
					<Header as='h3'>{intl.get('components.OperationSetting.common-setting')}</Header>
					<Form.Group widths={4}>
						<Form.Input
							label={intl.get('components.OperationSetting.pay-link')}
							name='TopUpLink'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.TopUpLink}
							type='link'
							placeholder={intl.get('components.OperationSetting.for-example-a-purchase-link-from-a-card-issuing-website')}
						/>
						<Form.Input
							label={intl.get('components.OperationSetting.chat-link')}
							name='ChatLink'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.ChatLink}
							type='link'
							placeholder={intl.get('components.OperationSetting.for-example-the-deployment-address-of-ChatGPT-Next-Web')}
						/>
						<Form.Input
							label={intl.get('components.OperationSetting.unit-dollar-limit')}
							name='QuotaPerUnit'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.QuotaPerUnit}
							type='number'
							step='0.01'
							placeholder={intl.get('components.OperationSetting.the-amount-at-which-one-unit-of-currency-can-be-exchanged')}
						/>
						<Form.Input
							label={intl.get('components.OperationSetting.failed-retry-times')}
							name='RetryTimes'
							type={'number'}
							step='1'
							min='0'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.RetryTimes}
							placeholder={intl.get('components.OperationSetting.failed-retry-times')}
						/>
					</Form.Group>
					<Form.Group inline>
						<Form.Checkbox
							checked={inputs.DisplayInCurrencyEnabled === 'true'}
							label={intl.get('components.OperationSetting.the-amount-is-displayed-in-monetary-form')}
							name='DisplayInCurrencyEnabled'
							onChange={handleInputChange}
						/>
						<Form.Checkbox
							checked={inputs.DisplayTokenStatEnabled === 'true'}
							label={intl.get('components.OperationSetting.billing-API-shows-token-credits-rather-than-user-credits')}
							name='DisplayTokenStatEnabled'
							onChange={handleInputChange}
						/>
						<Form.Checkbox
							checked={inputs.DrawingEnabled === 'true'}
							label={intl.get('components.OperationSetting.enable-drawing-function')}
							name='DrawingEnabled'
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Button
						onClick={() => {
							submitConfig('general').then();
						}}
					>
						{intl.get('components.OperationSetting.save-common-settings')}
					</Form.Button>
					<Divider />
					{/* 绘图设置 */}
					<Header as='h3'>{intl.get('components.OperationSetting.drawingSettings')}</Header>
					<CheckboxWarpper>
						<Space>
							<Checkbox
								checked={drawingSetting.enableDrawingSettings}
								name='enableDrawingSettings'
								onChange={(e) => {
									setDrawingSetting((drawing) => ({
										...drawing,
										enableDrawingSettings: e.target.checked,
									}));
									updateOption(`enableDrawingSettings`, String(e.target.checked));
								}}
							>
								{intl.get('components.OperationSetting.enableDrawingSettings')}
							</Checkbox>
							<Checkbox
								checked={drawingSetting.allowCallback}
								name='allowCallback'
								onChange={(e) => {
									setDrawingSetting((drawing) => ({
										...drawing,
										allowCallback: e.target.checked,
									}));
									updateOption(`allowCallback`, String(e.target.checked));
								}}
							>
								{intl.get('components.OperationSetting.allowCallback')}
							</Checkbox>
						</Space>
					</CheckboxWarpper>
					<div
						style={{
							paddingTop: '8px',
						}}
					>
						<Form.Button
							onClick={() => {
								console.log(drawingSetting);
								updateOption();
							}}
						>
							{intl.get('components.OperationSetting.saveDrawingSettings')}
						</Form.Button>
					</div>
					<Divider />
					<Header as='h3'>{intl.get('components.OperationSetting.log-settings')}</Header>
					<Form.Group inline>
						<Form.Checkbox
							checked={inputs.LogConsumeEnabled === 'true'}
							label={intl.get('components.OperationSetting.enable-quota-consumption-logging')}
							name='LogConsumeEnabled'
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Group widths={4}>
						<Form.Input
							label={intl.get('components.OperationSetting.object-time')}
							value={historyTimestamp}
							type='datetime-local'
							name='history_timestamp'
							onChange={(e, { name, value }) => {
								setHistoryTimestamp(value);
							}}
						/>
					</Form.Group>
					<Form.Button
						onClick={() => {
							deleteHistoryLogs().then();
						}}
					>
						{intl.get('components.OperationSetting.clearing-historical-logs')}
					</Form.Button>
					<Divider />
					<Header as='h3'>{intl.get('components.OperationSetting.data-board')}</Header>
					<Form.Checkbox
						checked={inputs.DataExportEnabled === 'true'}
						label={intl.get('components.OperationSetting.enable-data-board')}
						name='DataExportEnabled'
						onChange={handleInputChange}
					/>
					<Form.Group>
						<Form.Input
							label={intl.get('components.OperationSetting.data-kanban-update-interval')}
							name='DataExportInterval'
							type={'number'}
							step='1'
							min='1'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.DataExportInterval}
							placeholder={intl.get('components.OperationSetting.data-kanban-update-interval')}
						/>
						<Form.Select
							label={intl.get('components.OperationSetting.data-Kanban-default-time-granularity-show')}
							options={timeOptions}
							name='DataExportDefaultTime'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.DataExportDefaultTime}
							placeholder={intl.get('components.OperationSetting.data-Kanban-default-time-granularity')}
						/>
					</Form.Group>
					<Divider />
					<Header as='h3'>{intl.get('components.OperationSetting.monitoring-settings')}</Header>
					<Form.Group widths={3}>
						<Form.Input
							label={intl.get('components.OperationSetting.max-response-time')}
							name='ChannelDisableThreshold'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.ChannelDisableThreshold}
							type='number'
							min='0'
							placeholder={intl.get(
								'components.OperationSetting.when-running-all-channel-tests-the-channel-is-automatically-disabled-after-this-time'
							)}
						/>
						<Form.Input
							label={intl.get('components.OperationSetting.quota-reminder-threshold')}
							name='QuotaRemindThreshold'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.QuotaRemindThreshold}
							type='number'
							min='0'
							placeholder={intl.get('components.OperationSetting.quota-reminder-threshold-to-email')}
						/>
					</Form.Group>
					<Form.Group inline>
						<Form.Checkbox
							checked={inputs.AutomaticDisableChannelEnabled === 'true'}
							label={intl.get('components.OperationSetting.automatic-disable-channel-enabled')}
							name='AutomaticDisableChannelEnabled'
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Button
						onClick={() => {
							submitConfig('monitor').then();
						}}
					>
						{intl.get('components.OperationSetting.save-monitoring-settings')}
					</Form.Button>
					<Divider />
					<Header as='h3'>{intl.get('components.OperationSetting.quota-setting')}</Header>
					<Form.Group widths={4}>
						<Form.Input
							label={intl.get('components.OperationSetting.quota-for-new-user')}
							name='QuotaForNewUser'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.QuotaForNewUser}
							type='number'
							min='0'
							placeholder={intl.get('components.OperationSetting.for-example-100')}
						/>
						<Form.Input
							label={intl.get('components.OperationSetting.pre-consumed-quota')}
							name='PreConsumedQuota'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.PreConsumedQuota}
							type='number'
							min='0'
							placeholder={intl.get('components.OperationSetting.after-the-request-is-over-more-refund-and-less-payment')}
						/>
						<Form.Input
							label={intl.get('components.OperationSetting.quota-for-inviter')}
							name='QuotaForInviter'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.QuotaForInviter}
							type='number'
							min='0'
							placeholder={intl.get('components.OperationSetting.for-example-2000')}
						/>
						<Form.Input
							label={intl.get('components.OperationSetting.quota-for-invitee')}
							name='QuotaForInvitee'
							onChange={handleInputChange}
							autoComplete='new-password'
							value={inputs.QuotaForInvitee}
							type='number'
							min='0'
							placeholder={intl.get('components.OperationSetting.for-example-1000')}
						/>
					</Form.Group>
					<Form.Button
						onClick={() => {
							submitConfig('quota').then();
						}}
					>
						{intl.get('components.OperationSetting.save-limit-settings')}
					</Form.Button>
					<Divider />
					<Header as='h3'>{intl.get('components.OperationSetting.magnification-setting')}</Header>
					<Form.Group widths='equal'>
						<Form.TextArea
							label={intl.get('components.OperationSetting.model-fixed-price')}
							name='ModelPrice'
							onChange={handleInputChange}
							style={{ minHeight: 250, fontFamily: 'JetBrains Mono, Consolas' }}
							autoComplete='new-password'
							value={inputs.ModelPrice}
							placeholder={intl.get('components.OperationSetting.is-a-json')}
						/>
					</Form.Group>
					<Form.Group widths='equal'>
						<Form.TextArea
							label={intl.get('components.OperationSetting.model-ratio')}
							name='ModelRatio'
							onChange={handleInputChange}
							style={{ minHeight: 250, fontFamily: 'JetBrains Mono, Consolas' }}
							autoComplete='new-password'
							value={inputs.ModelRatio}
							placeholder={intl.get('components.OperationSetting.is-JSON-key-is-model-name-value-is-magnification')}
						/>
					</Form.Group>
					<Form.Group widths='equal'>
						<Form.TextArea
							label={intl.get('components.OperationSetting.group-ratio')}
							name='GroupRatio'
							onChange={handleInputChange}
							style={{ minHeight: 250, fontFamily: 'JetBrains Mono, Consolas' }}
							autoComplete='new-password'
							value={inputs.GroupRatio}
							placeholder={intl.get('components.OperationSetting.is-JSON-key-is-group-name-value-is-magnification')}
						/>
					</Form.Group>
					<Form.Group widths='equal'>
						<Form.TextArea
							label={intl.get('components.SystemSetting.rechargeRate')}
							name='TopupRatio'
							onChange={handleInputChange}
							style={{ minHeight: 250, fontFamily: 'JetBrains Mono, Consolas' }}
							autoComplete='new-password'
							value={inputs.TopupRatio}
						/>
					</Form.Group>
					<Form.Button
						onClick={() => {
							submitConfig('ratio').then();
						}}
					>
						{intl.get('components.OperationSetting.save-magnification-settings')}
					</Form.Button>
				</Form>
			</Grid.Column>
		</Grid>
	);
};

export default OperationSetting;
