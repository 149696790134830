import React from 'react';
import TokensTable from '../../components/TokensTable';
import { Layout } from '@douyinfe/semi-ui';
import { intl } from '../../lang';
const Token = () => (
	<>
		<Layout>
			<Layout.Header>
				<h3>{intl.get('pages.Token.index.my-token')}</h3>
			</Layout.Header>
			<Layout.Content>
				<TokensTable />
			</Layout.Content>
		</Layout>
	</>
);

export default Token;
