import React from 'react';
import ChannelsTable from '../../components/ChannelsTable';
import { Layout } from '@douyinfe/semi-ui';
import { intl } from '../../lang';
const File = () => (
	<>
		<Layout>
			<Layout.Header>
				<h3>{intl.get('pages.Channel.index.manage-channel')}</h3>
			</Layout.Header>
			<Layout.Content>
				<ChannelsTable />
			</Layout.Content>
		</Layout>
	</>
);

export default File;
